import { handleActions } from 'redux-actions';
import {
  SET_PAGE_STATE,
  ADBLOCK_STATUS,
} from './constants';

// Reducer is registered in makeRootReducer for all pages
export const reducer = handleActions({
  [SET_PAGE_STATE]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [ADBLOCK_STATUS]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
}, {});
