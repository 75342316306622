import { createLogger } from '@wxu/logger';

const logger = createLogger('html-collection');

export class HtmlCollection {
  constructor() {
    this._htmlRegions = {
      bodyScripts: new Set(),
      headScripts: new Set(),
      links: new Set(),
      metas: new Set(),
      styles: new Set(),
      title: new Set(),
    };
  }

  /**
   * Registers either an HTML string or array of HTML strings to the collection.
   * @param {string}          region
   * @param {string|string[]} html
   */
  registerHtml = (region, html) => {
    if (region === 'headScripts') {
      logger.debug(
        '\n\n\n',
        'Registering HTML for region: ',
        region,
        'HTML being registered: ',
        html,
        '\n\n\n',
      );
    }

    if (!Object.keys(this._htmlRegions).includes(region)) {
      logger.debug(`HTML region is not defined: ${region}`);
      return;
    }

    if (Array.isArray(html)) {
      html.forEach(item => this.registerHtml(region, item));
    } else {
      this._htmlRegions[region].add(html);
    }
  }

  /**
   * Get mapping of region names to HTML strings.
   * @return {Object<string,string>}
   */
  getHtml = () => {
    const regionNames = Object.keys(this._htmlRegions);
    const html = regionNames.reduce((accum, name) => {
      const regionElements = [...this._htmlRegions[name]];
      const regionHtml = regionElements.join('');

      accum[name] = regionHtml;

      return accum;
    }, {});

    return html;
  }
}
