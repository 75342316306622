import { getDateObj } from './getDateObj';

/**
 * Is dateA the day before dateB
 * @param {string} dateA
 * @param {string} dateB
 * @return {bool}
 */
export const isDayBefore = (dateA, dateB) => {
  if (!dateA || !dateB) return false;

  if (dateA && dateB) {
    const dateADate = getDateObj({ date: dateA });
    // Set to the day before to compare it to previous date
    const dayBeforeDateB = getDateObj({ date: dateB, daysFromDate: -1 });

    // Reset hours, minutes, and seconds
    dateADate.setHours(0, 0, 0);
    dayBeforeDateB.setHours(0, 0, 0);

    if (dateADate.toString() === dayBeforeDateB.toString()) return true;
  }
  return false;
};
