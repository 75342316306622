import { eventChannel, END } from 'redux-saga';
import {
  all,
  call,
  take,
  spawn,
} from 'redux-saga/effects';
import { inViewport } from '@wxu/in-viewport';
import { measureSinceStart } from '@wxu/performance/src/measureSinceStart';
import { logger, getContainerId } from '../../utils';

const rehydrateStartMark = 'REHYDRATE_MODULES_START';
const rehydrateEndMark = 'REHYDRATE_MODULES_END';

/**
 * Iterate over all modules in page config, and rehydrate each one.
 *
 * @param {import('@wxu/module-interface').ModuleInterface} moduleInterface
 * @param {Object}  pageConfig  The transformed page config
 */
export function* rehydrateSaga(modules, moduleInterface, pageConfig) {
  yield call(measureSinceStart, rehydrateStartMark);

  try {
    const modulesArray = yield call(getModulesArray, pageConfig);

    const effects = yield call(getRehydrateEffects, modules, modulesArray, moduleInterface);

    yield all(effects);
  } catch (err) {
    logger.error(err, 'Failed to rehydrate modules');
  }

  yield call(measureSinceStart, rehydrateEndMark);
}

/**
 * Gets all modules from the page config, returning an array of module configuration objects.
 *
 * @param {Object} pageConfig
 *
 * @returns {Object[]}
 */
export function getModulesArray(pageConfig) {
  const { regions } = pageConfig;
  const regionNames = Object.keys(regions);

  /* modulesArray is an array of objects, such as:
    [{
      module: SomeModule,
      region: 'nameOfRegion'
    }]
    */
  const modulesArray = regionNames.reduce(
    (modulesAccum, regionName) => {
      const mods = regions[regionName] || [];

      const mappedMods = mods.map(mod => ({
        module: mod,
        region: regionName,
      }));

      return [
        ...modulesAccum,
        ...mappedMods,
      ];
    },
    [],
  );

  return modulesArray;
}

/**
 * Gets an array of call effects to be called concurrently.
 *
 * @param {Object[]}  modulesArray
 * @param {import('@wxu/module-interface').ModuleInterface} moduleInterface
 *
 * @returns {call[]}
 */
export function getRehydrateEffects(modules, modulesArray, moduleInterface) {

  /**
   * Special handling for ad slot inside some modules, Wxu-Web rehydrate will clear the
   * element and cause Helios to lose reference to the element in DOM, adding here to emit
   * the event WXU_WEB_REHYDRATED so helios can init the
   * Flex Leader, WX_MidLeader, MW_Position2, WX_SpotLight, WX_PromoDriver1 ad slot
   */
  let moduleRehydratedCount = 0;
  let rehydrateEffectCount = 0;
  const rehydratedCallback = () => {
    moduleRehydratedCount++;

    if (moduleRehydratedCount === rehydrateEffectCount) {
      try {
        window.__HeliosQ ??= [];
        window.__HeliosQ.push((core) => {
          core.emit('WXU_WEB_REHYDRATED');
        });
      } catch (err) {
        logger.warn(err, 'Failed to emit event: WXU_WEB_REHYDRATED');
      }
    }
  };

  const rehydrateEffect = modulesArray.map(({ module, region }) => spawn(
    rehydrateModule,
    modules,
    moduleInterface,
    region,
    module,
    rehydratedCallback,
  ));

  rehydrateEffectCount = rehydrateEffect.length;

  return rehydrateEffect;
}

/**
 * Execute a module's client loader, which is responsible for how the module rehydrates.
 * If Helios exist, then do not redyrate the particular module for WxuAd
 *
 * @param {import('@wxu/module-interface').ModuleInterface} moduleInterface
 * @param {string}  region
 * @param {Object}  module  The module as defined in the page config.
 */
export function* rehydrateModule(modules, moduleInterface, region, module, rehydratedCallback) {
  const {
    component: name,
    props,
  } = module;

  try {
    /**
     * to revepent the rehydreate of WxuAd componet
     */
    if (name === 'WxuAd') {
      rehydratedCallback();
      return;
    }

    const containerId = yield call(getContainerId, {
      region,
      name,
      uuid: props.uuid,
      anchorName: props.anchorName,
    });
    const mod = yield call(getWebModule, modules, name);
    const {
      moduleLazyload,
      offsetTop = 0,
      offsetRight = 0,
      offsetBottom = 0,
      offsetLeft = 0,
    } = props;

    if (!mod || !mod.clientLoader) {
      // Don't log as error, as a module may intentionally not have a client loader.
      logger.debug(`${name} missing clientLoader`);
      // bail out early if no client loader
      rehydratedCallback();

      return;
    }

    if (moduleLazyload) {
      const serverRenderedContainer = document.getElementById(containerId);

      // Module has requested lazyloading
      if (window && window.IntersectionObserver) {
        // Intersection Observer supported
        const observerOptions = {
          // rootMargin is the margin around the in-view viewport(screen)
          rootMargin: `${offsetTop}px ${offsetRight}px ${offsetBottom}px ${offsetLeft}px`,
        };
        const channel = yield call(
          createObserverChannel,
          serverRenderedContainer,
          observerOptions,
        );

        // Set up an event channel to prevent loading any assets until module is viewport
        yield take(channel);
      } else {
        // Intersection Observer not supported, use fallback
        const inViewportOptions = {
          offset: {
            top: offsetTop,
            right: offsetRight,
            bottom: offsetBottom,
            left: offsetLeft,
          },
        };
        // Set up an event channel to prevent loading any assets until module is viewport
        const channel = yield call(
          createScrollandResizeChannel,
          serverRenderedContainer,
          inViewportOptions,
        );

        yield take(channel);
      }
    }
    // Import the client loader
    const loader = yield call(mod.clientLoader);

    // Rehydrate the module
    yield call(loader, moduleInterface, { props, containerId });
    yield call(measureSinceStart, `REHYDRATE_MODULE-${containerId}`);

    rehydratedCallback();

  } catch (err) {
    logger.warn(err, `Failed to rehydrate module: ${name}`);
  }
}

/**
 *
 * @param {string} name
 *
 * @returns {Object}
 */
export function getWebModule(modules, name) {
  return modules[name];
}

/**
 * Sets up an event channel with IntersectObserver
 */
export function createObserverChannel(serverRenderedContainer, observerOptions) {
  return eventChannel((emit) => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      // Module is in view, continue loading assets and detach observer
      if (entry.isIntersecting) {
        emit(true);
        observer.unobserve(serverRenderedContainer);
      }

    }, observerOptions);

    observer.observe(serverRenderedContainer);

    return () => emit(END);
  });
}

/**
 * Sets up an event channel with event listeners
 * Fallback for IE since it does not support IntersectObserver
 */
export function createScrollandResizeChannel(serverRenderedContainer, inViewportOptions) {
  return eventChannel((emit) => {
    window.addEventListener('scroll', () => {
      // Module is in view, continue loading assets
      if (inViewport(serverRenderedContainer, inViewportOptions)) {
        emit(true);
      }
    });

    window.addEventListener('resize', () => {
      // Module is in view, continue loading assets
      if (inViewport(serverRenderedContainer, inViewportOptions)) {
        emit(true);
      }
    });

    return () => emit(END);
  });
}
