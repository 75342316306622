import {
  createSelector,
} from 'reselect';
import get from 'lodash/get';
import {
  dalSelector,
  getDalDataSelector,
  createDalKey,
} from '@wxu/contexts/src/redux-dal/selectors';
import {
  dsxLocaleSelector,
  localeSelector,
} from '@wxu/contexts/src/i18n/selectors';
import {
  preferredLocationIanaTimeZoneSelector,
} from '@wxu/contexts/src/preferred-location/selectors';
import { formatLongMonthDateYear } from '@wxu/dates';
import {
  REDUCER_KEY,
} from '../constants';

/**
 * Gets selector which returns DAL UrlConfig for assetName
 *
 * @param {string} assetName - assetName from DSX video APIs
 * @example /some/collection/video/title-of-video
 *
 * @return {function} Selector which returns UrlConfig for assetName
 */
export const getVideoAssetUrlConfigSelector = assetName => createSelector(
  dsxLocaleSelector,
  locale => ({
    name: 'getCMSAssetsUrlConfig',
    params: {
      language: locale,
      query: {
        type: {
          $in: 'video',
        },
        assetName: {
          $in: assetName,
        },
      },
    },
  })
);

/**
 *
 * @param {string} assetId - video asset Id
 * @param {string} dsxLocale - example, en_US
 * @returns video object
 */
export const getVideoAssetByIdSelector = assetId => createSelector(
  dsxLocaleSelector,
  dalSelector,
  (language, dal) => {
    const dalKey = createDalKey({ language, assetId });
    const video = dal?.getCMSAssetByIDUrlConfig?.[dalKey]?.data;

    return video;
  }
);


/**
 * Gets selector which returns raw video asset DAL data for assetName
 *
 * @param {string} assetName - assetName from DSX video APIs
 * @example /some/collection/video/title-of-video
 *
 * @return {function} Selector which returns video asset data for assetName
 */
export const getVideoAssetDataSelector = assetName => createSelector(
  getVideoAssetUrlConfigSelector(assetName),
  getDalDataSelector,
  (config, getDataFromConfig) => {
    const data = getDataFromConfig(config);

    return get(data, '[0]', null);
  }
);

/**
 * Returns the transformed active video asset from video context Store
 *
 * @param {object} state - The redux Store
 * @return {object} Selector which returns video asset data for assetName
 */
export const activeVideoSelector = state => get(state, [REDUCER_KEY, 'activeVideo']);

/**
 * Returns the previous video asset from video context Store
 *
 * @param {object} state - The redux Store
 * @return {object} Selector which returns video asset data for assetName
 */
export const previousVideoSelector = state => get(state, [REDUCER_KEY, 'previousVideo']);

/**
 * Returns the id of the active video
 *
 * @return {string} Active video id
 */
export const activeVideoIdSelector = createSelector(
  activeVideoSelector,
  activeVideo => get(activeVideo, 'id', '')
);

/**
 * Returns the title of the active video
 *
 * @return {string} Active video title
 */
export const activeVideoTitleSelector = createSelector(
  activeVideoSelector,
  activeVideo => get(activeVideo, 'title', '')
);

/**
 * Returns the transcript of the active video
 *
 * @return {string} Active video transcript
 */
export const activeVideoTranscriptSelector = createSelector(
  activeVideoSelector,
  activeVideo => get(activeVideo, 'transcript', '')
);

/**
 * Returns the source/embed Url of the active video
 *
 * @return {string} Active video transcript
 */
export const activeVideoEmbedUrlSelector = createSelector(
  activeVideoSelector,
  activeVideo => activeVideo?.sources[1].file ?? ''
);

/**
 * Returns the description of the active video
 *
 * @return {string} Active video description
 */
export const activeVideoDescriptionSelector = createSelector(
  activeVideoSelector,
  activeVideo => get(activeVideo, 'description', '')
);

/**
 * Returns the duration of the active video
 *
 * @return {string} Active video duration
 */
export const activeVideoDurationSelector = createSelector(
  activeVideoSelector,
  activeVideo => activeVideo?.duration ?? '',
);

/**
 * Returns the Fastly fronted URL of the base image placeholder of the active video
 *
 * @return {string} Base placeholder image URL
 */
export const activeVideoImageSelector = createSelector(
  activeVideoSelector,
  activeVideo => get(activeVideo, 'image')
);

/**
 * Returns the SEO Friendly image URL of the active video
 *
 * @return {string} SEO Friendly image URL
 */
export const activeVideoSeoImageSelector = createSelector(
  activeVideoSelector,
  activeVideo => {
    let seoImage = get(activeVideo, 'seoImage', '');

    if (seoImage.trim() === '') {
      seoImage = get(activeVideo, 'image');
    }
    return seoImage;
  }
);

/**
 * Returns the publishdate of the active video
 *
 * @return {string} Active video publishdate
 */
export const activeVideoDatePublishedSelector = createSelector(
  activeVideoSelector,
  activeVideo => get(activeVideo, 'publishdate', '')
);

/**
 * Returns the lastmodifieddate of the active video
 *
 * @return {string} Active video lastmodifieddate
 */
export const activeVideoDateModifiedSelector = createSelector(
  activeVideoSelector,
  localeSelector,
  preferredLocationIanaTimeZoneSelector,
  (activeVideo, locale, timeZone) => {
    const rawDateModified = activeVideo?.lastmodifieddate ?? '';

    const dateModified = formatLongMonthDateYear({
      date: rawDateModified,
      locale,
      timeZone,
    });

    return dateModified;
  }
);

/**
 * Returns the lastmodifieddate of the active video in ISO format
 *
 * @return {string} Active video lastmodifieddate in ISO format
 */
export const activeVideoDateModifiedInISOSelector = createSelector(
  activeVideoSelector,
  (activeVideo) => activeVideo?.lastmodifieddate ?? ''
);

/**
 * Returns the Collection ID of the active video
 *
 * @return {string} Active video collection
 */
export const activeVideoCollectionIdSelector = createSelector(
  activeVideoSelector,
  activeVideo => get(activeVideo, 'pcollid', '')
);

/**
 * Returns the assetName of the active video
 *
 * @return {string} Active video assetName
 */
export const activeVideoNameSelector = createSelector(
  activeVideoSelector,
  activeVideo => get(activeVideo, 'assetName', '')
);

/**
 * Returns the providername of the active video
 *
 * @return {string} Active video providername
 */
export const activeVideoProviderNameSelector = createSelector(
  activeVideoSelector,
  activeVideo => activeVideo?.providername ?? '',
);

