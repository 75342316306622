/**
 * The translation function
 *
 * @param  {string} namespace               Translation namespace
 * @param  {string} key                     Translation key
 * @param  {Object} [options]               Optional arguments
 * @param  {string} [options.defaultValue]  Optional default string to use when translation does not exist
 * @param  {Object} [options.templateArgs]  Map of template variables to values
 * @param  {string} [options.language]      Explicitly defined language in `xx-XX`` or `xx`` form
 * @param  {Object} [options.i18n]          Store for all translations. Defaults to `window.__i18n`
 *
 * @return {string}
 */
export function translate(namespace, key, {
  defaultValue = key,
  language = 'en-US',
  templateArgs = {},
  i18n = (typeof window === 'undefined') ? {} : window.__i18n,
} = {}) {
  /**
   * @type {string}
   */
  let template = i18n?.[language]?.[namespace]?.[key] || defaultValue;

  for (const [variable, value] of Object.entries(templateArgs)) {
    // Support old variable substitution, just in case.
    const oldVariablePattern = `{{${variable}}}`;
    // New substitution syntax mimics JS template strings.
    const newVariablePattern = `\\\${${variable}}`;
    const pattern = `${oldVariablePattern}|${newVariablePattern}`;
    const regex = new RegExp(pattern, 'g');

    template = template.replace(regex, value);
  }

  return template;
}
