import { createSelector } from 'reselect';
import get from 'lodash/get';
import { getDalDataSelector } from '@wxu/contexts/src/redux-dal/selectors';
import {
  localeSelector,
} from '@wxu/contexts/src/i18n/selectors';
import {
  preferredLocationGeocodeSelector,
  preferredLocationIanaTimeZoneSelector,
} from '@wxu/contexts/src/preferred-location/selectors';
import { formatTimeWithTimezone } from './formatDates';

export const getSunV3CurrentDateTimeByGeocodeSelector = createSelector(
  getDalDataSelector,
  preferredLocationGeocodeSelector,
  (dal, geocode) => {
    const name = 'getSunV3CurrentDateTimeByGeocodeUrlConfig';
    const params = {
      geocode,
    };

    const dateTime = dal({ name, params });

    return dateTime;
  }
);

export const preferredLocationTimeSelector = validTimeLocal => createSelector(
  getSunV3CurrentDateTimeByGeocodeSelector,
  localeSelector,
  preferredLocationIanaTimeZoneSelector,
  (dateTime, locale, timeZone) => {
    const time = formatTimeWithTimezone({
      date: validTimeLocal,
      locale,
      timeZone,
      timeZoneAbbreviation: get(dateTime, 'timeZoneAbbreviation', ''),
    });

    return time;
  }
);

export const preferredLocationAsOfValidTimeSelector = (t, validTimeLocal) => createSelector(
  preferredLocationTimeSelector(validTimeLocal),
  (time) => {
    if (!time) return;

    return t('wxu-dates', 'asOfTime', {
      templateArgs: { time },
    });
  }
);

/**
 * Padding the value with a leading zero
 * @param val
 */
export function addLeadingZero(val) {
  return val < 10 ? `0${val}` : val;
}
