import { createLogger } from '@wxu/logger';
import { measure } from './measure';

const logger = createLogger('performance');

/**
 * Measures performance marks and logs them as debug messages.
 *
 * @param {string} name         Name of the measurement
 * @param {string} startMark    Start mark to measure against
 * @param {Object} [attributes] Optional attributes to add to log message
 */
export async function measureAndLog(name, startMark, attributes = {}) {
  try {
    const entry = await measure(name, startMark);

    logger.debug({
      ...attributes,
      duration: entry?.duration,
    }, name);

  } catch (err) {
    logger.warn(err, `Failed to get measurement for: ${name}`);
  }
}
