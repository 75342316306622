import { getDateObj } from './getDateObj';
import { addLeadingZero } from './selectors';

/**
 * Get the month (as a 2-digit number) for the date provided
 * Will return the month (as a 2-digit number) for new Date() if no date is provided
 * @param {object} date
 * @return {number}
 */
export const getMonthForDate = (date) => {
  const dateObj = date ? getDateObj({ date }) : new Date();

  return addLeadingZero(dateObj.getMonth() + 1);
};
