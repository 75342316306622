import { getDateOffsetInMinutes, getRawDateOffset } from './getDateOffset';
import { getNormalizedDateString } from './compatibleTzOffsetDate';

/**
 * Create an altered date object updated to the time based on an offset
 * @param  {object} date Original date object
 * @param  {bool} resetToStartOfHour Reset the time in the date object to reflect the top of the hour
 * @param  {string} daysFromDate Days from the original date that you want a date object for
 * @param  {string} daysBeforeDate Days before the original date that you want a date object for
 * @return {object} Altered date object
 */
export const getDateObj = ({
  date,
  resetToStartOfHour,
  resetToOffset,
  resetToStartOfMonth,
  resetToEndOfMonth,
  resetToStartOfWeek,
  resetToEndOfWeek,
  resetToHour,
  daysFromDate,
  daysBeforeDate,
  monthsBeforeDate,
  monthsAfterDate,
  yearsBeforeDate,
  yearsAfterDate,
}) => {
  if (!date) return {};

  const compatibleDate = getNormalizedDateString(date);
  const offset = getDateOffsetInMinutes(compatibleDate);
  const rawOffset = getRawDateOffset(compatibleDate);
  const dateObj = new Date(compatibleDate);
  let dateOffset = offset * 60000;

  if (compatibleDate.length === 10 && compatibleDate.indexOf('/') !== -1) {
    // date like "2021/08/29" returns date with 0 hours, no need to remove offset
    dateOffset = 0;
  }

  dateObj.setTime(dateObj.getTime() - dateOffset);

  // Use daysFromDate to create a date object for another date
  if (daysFromDate) {
    dateObj.setDate(dateObj.getDate() + daysFromDate);
  }

  if (daysBeforeDate) {
    dateObj.setDate(dateObj.getDate() - daysBeforeDate);
  }

  if (monthsBeforeDate) {
    dateObj.setMonth(dateObj.getMonth() - monthsBeforeDate);
  }

  if (monthsAfterDate) {
    dateObj.setMonth(dateObj.getMonth() + monthsAfterDate);
  }

  // Sometimes we need to compare dates based on the start of the hour
  if (resetToStartOfHour) {
    dateObj.setMinutes(0);
    dateObj.setSeconds(0);
  }

  // Sometimes we need to compare dates based on the minutes in the offset
  if (resetToOffset) {
    const offsetSplit = rawOffset.split(':');

    dateObj.setMinutes(offsetSplit[1]);
    dateObj.setSeconds(0);
  }

  // Resets to the start of the month for the given date
  if (resetToStartOfMonth) {
    dateObj.setDate(1);
  }

  // Resets to the end of the month for the given date
  if (resetToEndOfMonth) {
    const month = dateObj.getMonth();
    const year = dateObj.getFullYear();

    dateObj.setMonth(month + 1);
    dateObj.setDate(0);
    dateObj.setFullYear(year);
  }

  // Resets to the start of the week for the given date
  if (resetToStartOfWeek) {
    dateObj.setDate(dateObj.getDate() - dateObj.getDay());
  }

  // Resets to the end of the week for the given date
  if (resetToEndOfWeek) {
    dateObj.setDate(dateObj.getDate() - (dateObj.getDay()) + 6);
  }

  // Resets the hour of the given date to the hour
  // specified in resetToHour
  if (resetToHour) {
    dateObj.setHours(resetToHour);
  }

  if (yearsBeforeDate) {
    dateObj.setFullYear(dateObj.getFullYear() - 1);
  }

  if (yearsAfterDate) {
    dateObj.setFullYear(dateObj.getFullYear() + 1);
  }

  const val = dateObj;

  return val;
};
