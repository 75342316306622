import { createSelector } from 'reselect';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import {
  countryCodeSelector as i18nCountryCodeSelector,
} from '@wxu/contexts/src/i18n/selectors';
import { geoIPCountryCodeSelector } from '@wxu/contexts/src/user-location/selectors';
import {
  isUserLoggedInSelector,
} from '@wxu/contexts/src/user/selectors';
import {
  pageKeySelector,
  isFrontEndApiSelector,
  privacyHeaderSelector,
  isMobileSelector,
} from '@wxu/contexts/src/page/selectors';
import {
  CA,
  USA_CCPA,
  RESTRICTIVE_REGIMES,
  US_REGIMES,
  Regime,
  JP,
} from '@wxu/dpr-sdk/src/configs/regimesConfig';
import {
  SALE_OF_DATA,
  SENSITIVE_DATA,
} from '@wxu/dpr-sdk/src/configs/purposesConfig';
import * as CONSTANTS from './constants';

export const isRegimeCCPASelector = createSelector(
  privacyHeaderSelector,
  privacyHeader => privacyHeader === USA_CCPA
);

export const isAnUSRegimeSelector = createSelector(
  privacyHeaderSelector,
  regime => US_REGIMES.includes(regime as Regime)
);

/**
 * Gets just the featureFlags object. The feature flags
 * are toggled on/off according to the opt-ins selected
 * by the user.
 *
 * @param {*} state
 */
export const featureFlagsSelector = state => get(
  state,
  [CONSTANTS.REDUCER_KEY, CONSTANTS.FEATURE_FLAGS],
  {},
);

/**
 * featureSelectorFactory
 *
 * Creates a selector for the appropriate feature,
 * from the featureKey for that feature.
 *
 * @param {*} featureKey
 */
export const featureSelectorFactory = featureKey => createSelector(
  featureFlagsSelector,
  featureFlags => get(featureFlags, [featureKey]),
);

/**
 * selects the entire consent object from state
 * @param {*} state
 */
export const dprConsentSelector = state => get(
  state,
  [CONSTANTS.REDUCER_KEY, CONSTANTS.CONSENT],
  {},
);

export const saleOfDataConsentSelector = createSelector(
  dprConsentSelector,
  (consents) => {
    const {
      DprSdk,
    } = __CLIENT__ && window || {};

    const saleOfDataConsent = consents[SALE_OF_DATA] ?? DprSdk?.getUserConsentWithDefaultValue?.(SALE_OF_DATA);

    return saleOfDataConsent;
  }
);

export const sensitiveDataConsentSelector = createSelector(
  dprConsentSelector,
  (consents) => {
    const {
      DprSdk,
    } = __CLIENT__ && window || {};

    const saleOfDataConsent = consents[SENSITIVE_DATA] ?? DprSdk?.getUserConsentWithDefaultValue?.(SENSITIVE_DATA);

    return saleOfDataConsent;
  }
);

/**
 * evar86 gdpr opt-ins
 */
export const gdprConsentOptInsSelector = () => {
  const { DprSdk } = window || {};

  if (typeof DprSdk?.getGdprConsentOptInsString === 'function') {
    return DprSdk.getGdprConsentOptInsString();
  }
  return undefined;
};

export const ccpaSaleOptInSelector = () => {
  const { DprSdk } = window || {};

  if (typeof DprSdk?.getCcpaSaleOptInString === 'function') {
    return DprSdk.getCcpaSaleOptInString();
  }
  return undefined;
};

/**
 * for allowAmazon
 */
export const allowAmazonSelector = featureSelectorFactory(CONSTANTS.DPR_AMAZON);

/**
 * for allowGeoIP
 */
export const allowGeoIPSelector = featureSelectorFactory(CONSTANTS.DPR_GEO_IP);

/**
 * for allow "Find Me"
 */
export const allowFindMeSelector = featureSelectorFactory(CONSTANTS.DPR_FIND_ME);

/**
 * for allowSocialLinks
 */
export const allowSocialLinksFooterSelector = createSelector(
  featureSelectorFactory(CONSTANTS.DPR_SOCIAL_LINKS_FOOTER),
  pageKeySelector,
  (allowSocialLinksFooter, pageKey) => {
    // do not display social links on these pages.
    const denyList = [
      'covid19map',
    ];

    if (denyList.includes(pageKey)) return false;

    return allowSocialLinksFooter;
  }
);

/**
 * allows for Embedded WxNodes
 */
export const allowFacebookWxNodeSelector = featureSelectorFactory(CONSTANTS.DPR_FACEBOOK_WXNODE);
export const allowInstagramWxNodeSelector = featureSelectorFactory(CONSTANTS.DPR_INSTAGRAM_WXNODE);
export const allowTwitterWxNodeSelector = featureSelectorFactory(CONSTANTS.DPR_TWITTER_WXNODE);
export const allowYoutubeWxNodeSelector = featureSelectorFactory(CONSTANTS.DPR_YOUTUBE_WXNODE);

/**
 * Selects the countryCode from geoIP, but
 * uses the i18nCountryCode as a fallback;
 */
export const countryCodeSelector = createSelector(
  geoIPCountryCodeSelector,
  i18nCountryCodeSelector,
  (geoIPCountryCode, countryCode) => geoIPCountryCode || countryCode,
);

/**
 * Boolean; returns whether to allow the Privacy Settings link.
 * Based upon regime; doesn't allow link if already exempt.
 */
export const allowPrivacySettingsLinkSelector = createSelector(
  privacyHeaderSelector,
  (regime) => [
    CA,
    ...US_REGIMES,
    ...RESTRICTIVE_REGIMES,
  ].includes(regime)
);

/**
 * Process a privacy link list as follows:
 *
 * Privacy Settings link:
 *  - For regimes not allowed, remove.
 *  - For RESTRICTIVE_REGIMES, remove link and add consent manager method.
 *
 * Do Not Sell link:
 *  - Add for: usa, usa-ccpa, usa-va only. Otherwise, remove.
 *  - Add respective link text:
 *    - reviewAdsSettings for usa, usa-va
 *    - doNotSell for usa-ccpa
 */
export function processPrivacyLinkList({
  linkList = [],
  regime,
  allowPrivacySettingsLink,
  pageKey,
  isMobile,
  isUserLoggedIn,
}) {
  if (!Array.isArray(linkList)) return linkList;

  const privacyLinkList = cloneDeep(linkList);

  processPrivacySettingsLink({
    privacyLinkList,
    regime,
    allowPrivacySettingsLink,
  });

  processDataPurposesLink({
    privacyLinkList,
    regime,
    pageKey,
    isMobile,
  });

  processDoNotSellLink({
    privacyLinkList,
    regime,
    pageKey,
    isMobile,
  });

  processSensitiveDataLink({
    privacyLinkList,
    regime,
    pageKey,
    isMobile,
    isUserLoggedIn,
  });

  return privacyLinkList;
}

export const processPrivacySettingsLink = ({
  privacyLinkList,
  regime,
  allowPrivacySettingsLink,
}) => {
  const privacySettingsIndex = privacyLinkList.findIndex(
    link => (
      link?.titleTranslationKey === 'privacySettings'
      // To process Main Menu "Privacy Settings" link
     || link?.titleTranslationKey === 'privacySettingsMenu'
    )
  );

  if (privacySettingsIndex > -1) {
    if (!allowPrivacySettingsLink) {
      // Remove privacy settings link if it's not allowed in
      // the current regime.
      privacyLinkList.splice(privacySettingsIndex, 1);

      return;
    }

    if (RESTRICTIVE_REGIMES.includes(regime)) {
      // Remove privacy settings link, and add consent manager method.
      const {
        DprSdk,
      } = __CLIENT__ && window || {};

      privacyLinkList[privacySettingsIndex].titleTranslationKey = 'cookieSettings';
      privacyLinkList[privacySettingsIndex].url = '';
      privacyLinkList[privacySettingsIndex].onClick = DprSdk?.showConsentManager;
    }

    if (US_REGIMES.includes(regime)
    && privacyLinkList[privacySettingsIndex].titleTranslationKey !== 'privacySettingsMenu'
    // Keep "Privacy Settings" in Main Menu
    ) {
      // Add usa text link to us regimes.
      privacyLinkList[privacySettingsIndex].titleTranslationKey = 'reviewAdsSettings';
    }
  }
};

export const processDataPurposesLink = ({
  privacyLinkList,
  regime,
  pageKey,
  isMobile,
}) => {
  const dataPurposesIndex = privacyLinkList.findIndex(
    link => link?.titleTranslationKey?.includes?.('dataPurposes')
  );

  if (dataPurposesIndex > -1) {
    if (regime !== JP) {
      // Data purposes only shows up in JP.
      privacyLinkList.splice(dataPurposesIndex, 1);

      return;
    }
    if (pageKey === 'dataPurposes' && isMobile) {
      // Force refresh to close the mobile main menu if already in data purposes.
      // Url changes after onClick. Execute after url has changed.
      privacyLinkList[dataPurposesIndex].urlWithOnClick = () => {
        setTimeout(() => {
          window.location.reload();
        }, 0);
      };
    }
  }
};

export const processDoNotSellLink = ({
  privacyLinkList,
  regime,
  pageKey,
  isMobile,
}) => {
  const doNotSellIndex = privacyLinkList.findIndex(
    link => link?.titleTranslationKey?.includes?.('doNotSell')
  );

  if (doNotSellIndex > -1) {
    if (!US_REGIMES.includes(regime)) {
      // Remove do not sell link for non us regimes.
      privacyLinkList.splice(doNotSellIndex, 1);

      return;
    }
    if (pageKey === 'privacySettings' && isMobile) {
      // Force refresh to close the mobile main menu if already in privacy setting.
      // Url changes after onClick. Execute after url has changed.
      privacyLinkList[doNotSellIndex].urlWithOnClick = () => {
        setTimeout(() => {
          window.location.reload();
        }, 0);
      };
    }

    if (regime !== USA_CCPA && US_REGIMES.includes(regime)) {
      // Add usa text link to us regimes (but not usa-ccpa).
      privacyLinkList[doNotSellIndex].titleTranslationKey = 'saleOfData';
    }
  }
};

export const processSensitiveDataLink = ({
  privacyLinkList,
  regime,
  pageKey,
  isMobile,
  isUserLoggedIn,
}) => {
  const sensitiveDataIndex = privacyLinkList.findIndex(
    link => link?.titleTranslationKey?.includes?.('sensitiveData')
  );

  if (sensitiveDataIndex > -1) {
    if (pageKey === 'privacySettings' && isMobile) {
    // Force refresh to close the mobile main menu if already in privacy setting.
    // Url changes after onClick. Execute after url has changed.
      privacyLinkList[sensitiveDataIndex].urlWithOnClick = () => {
        setTimeout(() => {
          window.location.reload();
        }, 0);
      };
    }

    if (regime !== USA_CCPA || !isUserLoggedIn) {
      privacyLinkList.splice(sensitiveDataIndex, 1);
    }
  }
};

/**
 * Returns the processPrivacyLinkList function
 * with regime and allowPrivacySettingsLink already defined.
 */
export const processPrivacyLinkListSelector = createSelector(
  privacyHeaderSelector,
  allowPrivacySettingsLinkSelector,
  pageKeySelector,
  isMobileSelector,
  isUserLoggedInSelector,
  (regime, allowPrivacySettingsLink, pageKey, isMobile, isUserLoggedIn) => ({ linkList }) => processPrivacyLinkList({
    linkList,
    regime,
    allowPrivacySettingsLink,
    pageKey,
    isMobile,
    isUserLoggedIn,
  })
);

/** *****************\
 *  ADS SELECTORS  *
\*******************/

/**
 * for allowBurdaAdEngine
 */
export const allowBurdaAdEngineSelector = featureSelectorFactory(
  CONSTANTS.DPR_BURDA_AD_ENGINE
);

/**
 * for allowHeaderBidders
 */
export const allowHeaderBiddersSelector = featureSelectorFactory(
  CONSTANTS.DPR_HEADER_BIDDERS
);

/**
 * for allowBurdaHeaderBidders
 */
export const allowBurdaHeaderBiddersSelector = featureSelectorFactory(
  CONSTANTS.DPR_BURDA_HEADER_BIDDERS
);

/**
 * for allowCriteoSlot
 */
export const allowCriteoSlotSelector = featureSelectorFactory(
  CONSTANTS.DPR_CRITEO_SLOT
);

/**
 * for allowIndexExchange
 */
export const allowIndexExchangeSelector = featureSelectorFactory(
  CONSTANTS.DPR_INDEX_EXCHANGE
);

/**
 * Cust Params with PII. used for passing pii in cust params.
 */
export const allowCustParamsPiiSelector = featureSelectorFactory(CONSTANTS.DPR_CUST_PARAMS_PII);


/**
 * GPT
 */
export const allowGptSelector = featureSelectorFactory(CONSTANTS.DPR_GPT);

/**
 * GPT with PII for Gdpr. used for npa flag, and blocking
 * headerBidders.
 */
export const allowGptPiiGDPRSelector = featureSelectorFactory(CONSTANTS.DPR_GPT_PII_GDPR);

/**
 * GPT with PII sale-of-data USA. use for rdp flag, and blocking
 * headerBidders.
 */
export const allowGptPiiSaleUSASelector = featureSelectorFactory(CONSTANTS.DPR_GPT_PII_USA);

/**
 * Medallia
 */
export const allowMedalliaSelector = featureSelectorFactory(CONSTANTS.DPR_MEDALLIA);

/**
 * for allowOutbrain
 */
export const allowOutbrainSelector = featureSelectorFactory(CONSTANTS.DPR_OUTBRAIN);

/**
 * for allowRubicon
 */
export const allowRubiconSelector = featureSelectorFactory(
  CONSTANTS.DPR_RUBICON
);

/**
 * for allowTaboola
 */
export const allowTaboolaSelector = featureSelectorFactory(CONSTANTS.DPR_TABOOLA);

/** ***********************\
 *  ANALYTICS SELECTORS  *
\*************************/

/**
 * for window.googletag.pubads().setPrivacySettings
 */
export const allowGoogleNonLimitedAdsSelector = featureSelectorFactory(
  CONSTANTS.DPR_GOOGLE_NON_LIMITED_ADS
);

/**
 * Full Metrics Allowed?
 *
 * This selector indicates whether the metrics beacons (for
 * "pageview" events, video events, and any Daybreak Metrics
 * scrolling or clicking "trackString" events) are permitted
 * to send their full normal suite of property values, or (if
 * full metrics are NOT allowed) only a reduced set of values,
 * with PII and near-PII values being replaced by a generic
 * "opted-out" string.
 */
export const allowAirlyticsFullMetricsSelector = createSelector(
  featureSelectorFactory(CONSTANTS.DPR_AIRLYTICS_FULL_METRICS),
  isFrontEndApiSelector,
  pageKeySelector,
  (allowAirlyticsFullMetrics, isFrontEndApi, pageKey) => allowAirlyticsFullMetrics
    // WEB-16485: For the covid19 webview pages, force GDPR opt out on metrics beacons.
    && !(isFrontEndApi && pageKey.includes('covid19'))
);

export const allowMparticleFullMetricsSelector = createSelector(
  featureSelectorFactory(CONSTANTS.DPR_MPARTICLE_FULL_METRICS),
  isFrontEndApiSelector,
  pageKeySelector,
  (allowMparticleFullMetrics, isFrontEndApi, pageKey) => allowMparticleFullMetrics
    // WEB-16485: For the covid19 webview pages, force GDPR opt out on metrics beacons.
    && !(isFrontEndApi && pageKey.includes('covid19'))
);

/**
 * Nielsen
 */
export const allowNielsenSelector = featureSelectorFactory(CONSTANTS.DPR_NIELSEN);

/**
 * Comscore
 */
export const allowComscoreSelector = featureSelectorFactory(CONSTANTS.DPR_COMSCORE);

/** **********************\
 *  PARTNERS SELECTORS  *
\************************/

/**
 * for allowPrisma
 */
export const allowPrismaGPTSelector = featureSelectorFactory(CONSTANTS.DPR_PRISMA_GPT);

/**
 * for allowWfxTriggers
 */
export const allowWfxTriggersSelector = featureSelectorFactory(CONSTANTS.DPR_WFX_TRIGGERS);
