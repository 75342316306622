import { Store as ReduxStore, Action, Reducer } from 'redux';
import { Saga, SagaMiddleware } from 'redux-saga';
import { languageEntityCodeSelector } from '@wxu/contexts/src/i18n/selectors';
import { HtmlCollection } from '@wxu/html-collection';
import { translate } from '@wxu/translations';

// TODO: update after @wxu/redux-create-store has types available
export interface Store extends ReduxStore {
  runSaga?: SagaMiddleware['run'];
  registerReducer?: (key: string, reducer: Reducer<unknown, Action<unknown>>) => void;
}

// TODO: replace this with type from @wxu/translations
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type I18n = Record<string, any>;

declare global {
  interface Window {
    __i18n: I18n;
  }
}

// TODO: Should be moved to the translations package, when we rewrite it in TypeScript
export interface TranslateOptions {
  defaultValue?: string;
  templateArgs?: Record<string, string>;
  language?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Selector = (state: Record<string, any>) => any;

/**
 * ModuleInterface provides a common interface for modules (and more)
 * to interact with pieces of the larger application.
 */
export class ModuleInterface {
  store: Store;

  i18n: I18n;

  css: Set<string>;

  private htmlCollection: HtmlCollection;

  registerHtml: HtmlCollection['registerHtml'];

  getHtml: HtmlCollection['getHtml'];

  constructor({ store }: { store: Store }) {
    this.store = store;
    this.i18n = (typeof window === 'undefined') ? {} : window.__i18n;
    this.css = new Set();
    this.insertCssServer = this.insertCssServer.bind(this);
    this.htmlCollection = new HtmlCollection();
    this.registerHtml = this.htmlCollection.registerHtml;
    this.getHtml = this.htmlCollection.getHtml;
  }

  getCss(): string {
    return [...this.css].reverse().join('');
  }

  /**
   * Gets the store from the moduleInterface safely.
   */
  getStore(): Store {
    return this.store;
  }

  /**
   * Gets the store's `dispatch` method.
   */
  getDispatch(): Store['dispatch'] {
    return this.store.dispatch;
  }

  /**
   * Dispatches a redux action to the store.
   *
   * @param      {Action}  action  The action
   */
  dispatch(action: Action) {
    return this.store.dispatch(action);
  }

  /**
   * Gets the stored translations data.
   */
  getTranslations(): I18n {
    return this.i18n;
  }

  /**
   * Sets the stored translations data.
   */
  setTranslations(translations: I18n) {
    this.i18n = translations;
  }

  /**
   * Gets the store's state.
   */
  getState() {
    return this.store.getState();
  }

  /**
   * Gets the `_insertCss` method for the StyleContext provider.
   */
  getInsertCss({ client }: { client?: boolean } = {}) {
    if (typeof window === 'undefined') {
      return this.insertCssServer;
    }

    return client ? ModuleInterface.insertCssWeb : ModuleInterface.insertCssNoop;
  }

  private static insertCssWeb(...styles) {
    const removeCss = styles.map(style => style._insertCss());

    return () => removeCss.forEach(dispose => dispose());
  }

  private insertCssServer(...styles) {
    styles.forEach(style => this.css.add(style._getCss()));
  }

  private static insertCssNoop() { return null; }

  /**
   * Runs a saga, and returns its result as a Promise.
   */
  runSaga(saga: Saga, ...args) {
    return this.store.runSaga(saga, ...args).toPromise();
  }

  /**
   * Registers redux reducer to `key` in store's state.
   */
  registerReducer(key: string, reducer: Reducer<unknown, Action<unknown>>) {
    this.store.registerReducer(key, reducer);
  }

  /**
   * Translates a key under the given namespace.
   */
  t = (
    namespace: string,
    key: string,
    {
      defaultValue = key,
      language = this.select(languageEntityCodeSelector),
      templateArgs = {},
    }: TranslateOptions = {}
  ): string => translate(namespace, key, {
    defaultValue,
    language,
    templateArgs,
    i18n: this.i18n,
  });

  select(selector: Selector) {
    return selector(this.store.getState());
  }
}
