import { select, call, all } from 'redux-saga/effects';
import get from 'lodash/get';
import { contexts } from '@wxu/contexts/src/index.web';
import {
  contextListContextsSelector,
  contextDependencyGraphSelector,
} from '@wxu/contexts/src/selectors';
import { heliosSdkSelector } from '@wxu/contexts/src/moneytree/selectors/heliosSelectors';
import {
  clientLoader as reduxDalClientLoader,
} from '@wxu/contexts/src/redux-dal/client.loader';
import { createLogger } from '@wxu/logger';
import { measureSinceStart } from '@wxu/performance/src/measureSinceStart';

const logger = createLogger('pc-to-html');
const contextsStartMark = 'CONTEXTS_START';
const contextsEndMark = 'CONTEXTS_END';

/**
 * Calls each context's client loader.
 * @param {import('@wxu/module-interface').ModuleInterface} moduleInterface
 */
export function* loadContexts(moduleInterface) {
  yield call(measureSinceStart, contextsStartMark);

  try {
    // Load redux-dal context, register its reducer
    yield call(reduxDalClientLoader, moduleInterface);

    const contextLoaderMap = yield call(getContextLoadersMap);
    const contextsGraph = yield select(contextDependencyGraphSelector);

    for (const contextGroup of contextsGraph) {
      yield call(executeContextGroup, contextGroup, contextLoaderMap, moduleInterface);
    }
  } catch (err) {
    logger.error(err);
  }

  yield call(measureSinceStart, contextsEndMark);
}

/**
 * Concurrently fetch all context loaders, and return a mapping of context names to their clientLoaders.
 * @returns {Object<string,Function>}
 */
function* getContextLoadersMap() {
  const contextLoaderMap = {};
  let orderedContexts = yield select(contextListContextsSelector);
  const helios = yield select(heliosSdkSelector);

  if (helios) {
    // using Helios instead of moneytree saga
    orderedContexts = orderedContexts.filter((oc) => oc.attributes?.name !== 'ads');
  }

  const noop = () => null;
  const importLoaderEffects = orderedContexts.reduce((effects, currentContext) => {
    const contextName = get(currentContext, ['attributes', 'name']);
    // Default to noop in case client loader import function isn't defined
    const loaderImporter = contexts[contextName] || noop;

    return [
      ...effects,
      call(loaderImporter),
    ];
  }, []);

  // Import all context loaders
  const contextLoaders = yield all(importLoaderEffects);

  orderedContexts.forEach((currentContext, index) => {
    const contextName = get(currentContext, ['attributes', 'name']);

    // Map context name to its clientLoader
    contextLoaderMap[contextName] = contextLoaders[index];
  });

  return contextLoaderMap;
}

/**
 * Measure context loader with performance mark.
 *
 * @callback clientLoader
 * @param {import('@wxu/module-interface').ModuleInterface} moduleInterface
 * @param {string}                                          contextName
 */
async function callLoaderWithBeacons(loader, moduleInterface, contextName) {
  await loader(moduleInterface);
  measureSinceStart(`CONTEXT_DONE-${contextName}`);
}

/**
 * Call the clientLoaders for a group of contexts which can be called concurrently.
 * @param {Object[]}                contextGroup
 * @param {Object<string,Function>} contextLoaderMap
 * @param {import('@wxu/module-interface').ModuleInterface} moduleInterface
 */
function* executeContextGroup(contextGroup, contextLoaderMap, moduleInterface) {
  const concurrentEffects = contextGroup.reduce((effects, context) => {
    const name = get(context, ['attributes', 'name']);
    const loader = contextLoaderMap[name];

    if (loader) {
      return [
        ...effects,
        call(callLoaderWithBeacons, loader, moduleInterface, name),
      ];
    }

    return effects;
  }, []);

  yield all(concurrentEffects);
}
