const getSignIndices = (dateStr) => {
  const minusIndices = [];
  const plusIndices = [];

  // Store indices where - is found in the sting
  // Store indices where + is found in the sting
  for (let i = 0; i < dateStr.length; i++) {
    if (dateStr[i] === '-') minusIndices.push(i);
    if (dateStr[i] === '+') plusIndices.push(i);
  }

  return {
    minus: minusIndices,
    plus: plusIndices,
  };
};

const replaceSpaces = (dateStr) => {
  if (!/\s/g.test(dateStr)) {
    return dateStr;
  }

  let str = dateStr;

  // Replace the first space with a T
  str = str.replace(/\s+/, 'T');
  // Remove all other spaces
  str = str.replace(/\s+/g, '');

  return str;
};

const removeSeconds = (dateStr) => {
  if (!/\./g.test(dateStr)) {
    return dateStr;
  }

  const periodIndex = dateStr.indexOf('.');
  const signIndices = getSignIndices(dateStr);
  const { plus, minus } = signIndices;

  // Setting the index to 4 should remove all seconds if it comes at the end of the string
  let signIndex = periodIndex + 4;

  if (plus.length === 1 || minus.length > 2) {
    // If there is a sign present, the seconds exist between the period and the sign,
    // so we can be more specific
    signIndex = minus[2] || plus[0];
  }

  const periodSubstring = dateStr.substring(periodIndex, signIndex);
  const newDateStr = dateStr.replace(periodSubstring, '');

  return newDateStr;
};


// TODO: This needs to be regressed
// Hot fix for https://jira.weather.com:8443/browse/WEB-22044
const removeTimezoneAbbr = (dateStr) => {
  let newDateStr = dateStr;

  // If the last character is a Z, we only need to remove Z
  if (dateStr.includes('Z')) newDateStr = newDateStr.replace('Z', '+0000');

  const dateStrLastChar = parseInt(newDateStr[newDateStr.length - 1], 10);

  // If the last character is not a number, get everything starting from the first
  // character to the last character in the offset. This will remove the timezone abbreviation
  if (!Number.isInteger(dateStrLastChar)) {
    const signIndices = getSignIndices(newDateStr);
    const { plus, minus } = signIndices;

    // If plus sign is present
    // Or if a third minus sign is present...
    if (plus.length === 1 || minus.length > 2) {
      const signIndex = minus[2] || plus[0];

      // Get just the offset from the dateStr
      const formattedDate = newDateStr.substring(0, signIndex + 5);

      return formattedDate;
    }

    return newDateStr;
  }

  return newDateStr;
};

/**
 * Get a cross-browser-compatible date string, for use in the `Date` constructor.
 * @param  {string} date Date string as found in forecast data, e.g. `"2019-06-10T18:00:00-0400"`
 * @return {string} Compatible timezone-offset date string, e.g. `"2019/06/10 18:00:00-04:00"`
 */
export function getNormalizedDateString(date) {
  if (!date) return null;

  let dateStr = date;

  // If the date passed in is an object, convert it to a string
  if (typeof date === 'object') {
    const dateCopy = new Date(+date);

    dateStr = dateCopy.toISOString();
  }

  // If the date passed is a number, treat it as an epoch
  if (typeof date === 'number') return dateStr;

  dateStr = replaceSpaces(dateStr);
  dateStr = removeTimezoneAbbr(dateStr);
  dateStr = removeSeconds(dateStr);

  const TZ_OFFSET_REGEX = /(:\d+(\.\d+)?[+-])(\d{2})(\d{2})/;
  const TZ_REPLACE_PATTERN = '$1$3:$4';
  const compatibleDate = dateStr.replace(TZ_OFFSET_REGEX, TZ_REPLACE_PATTERN);

  // Some browsers need to be in the format YYYY/MM/mm
  const fixedCompatibleDate = compatibleDate.replace(/([0-9]{4})-([0-9]{2})-/, '$1/$2/').replace('T', ' ');

  if (new Date(fixedCompatibleDate).toString() === 'Invalid Date') {
    return compatibleDate;
  }

  return fixedCompatibleDate;
}
