import { getDateObj } from './getDateObj';
import { addLeadingZero } from './selectors';

/**
 * Get the day (as a 2-digit number) for the date provided
 * Will return the day (as a 2-digit number) for new Date() if no date is provided
 * @param {object} date
 * @return {number}
 */
export const getDayForDate = (date) => {
  const dateObj = date ? getDateObj({ date }) : new Date();

  return addLeadingZero(dateObj.getDate());
};
