/**
 * Returns the relative top of a dom element, if found.
 * @param {string} elementId the id of the dom element
 * @returns {number|undefined}
 */
function getRectTop(elementId) {
  const match = document.getElementById(elementId);

  if (match) {
    const rect = match.getBoundingClientRect();

    return rect.top;
  }
  return undefined;
}

/**
 * Scroll to an anchor by adjusting for fixed header.
 */
function scrollToAnchor() {
  const anchor = window.location.hash;
  const targetDiv = document.getElementById(anchor.slice(1));

  if (targetDiv) {
    const rect = targetDiv.getBoundingClientRect();
    const offset = getRectTop('MainContent') || 0;
    // This should match the height of fixed header,
    // no matter the scroll position
    const anchorOffset = window.pageYOffset + rect.top - offset;

    window.scrollTo(window.pageXOffset, anchorOffset);
  }
}

/**
 * Adds event listeners for load and hashchange events on window.
 */
export function addEventListeners() {
  window.addEventListener('load', scrollToAnchor);
  window.addEventListener('hashchange', scrollToAnchor);
}
