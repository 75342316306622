import {
  MS_PER_DAY,
  MS_PER_HR,
  SEC_PER_WK,
  SEC_PER_DAY,
  SEC_PER_HR,
} from '@wxu/time-units';
import { getNormalizedDateString } from './compatibleTzOffsetDate';

export * from './formatDates';
export * from './compatibleTimezone';
export * from './compatibleTzOffsetDate';
export * from './getDateOffset';
export * from './getDateObj';
export * from './getDateString';
export * from './isDayBefore';
export * from './diffInDays';
export * from './diffInMinutes';
export * from './selectors';
export * from './dateToDsxUtcDateString';
export * from './getDayForDate';
export * from './getFullYearForDate';
export * from './getMonthForDate';
export { roundDate } from './roundDate';

/**
 * Split the date format into individual parts
 * @param format
 * @returns {Array.<T>}
 */
function getFormatParts(format) {
  let match;
  const DATE_FORMATS_SPLIT = /((?:[^yMdHhmsaAZzE']+)|(?:'(?:[^']|'')*')|(?:E+|y+|M+|d+|H+|h+|m+|s+|a|A|Z|z))(.*)/;

  let parts = [];
  const { slice } = [];
  const concatMethod = (array1, array2, index) => array1.concat(slice.call(array2, index));

  while (format) {
    match = DATE_FORMATS_SPLIT.exec(format);

    if (match) {
      parts = concatMethod(parts, match, 1);
      format = parts.pop();
    } else {
      parts.push(format);
      format = null;
    }
  }
  // filter the empty strings
  // return parts.filter(entry => entry.trim() !== '');
  return parts;
}

/**
 * Capitalize first letter of the string
 * @param  string
 * @return {string}
 */
function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

/**
 * Padding the value with a leading zero
 * @param val
 */
function addLeadingZero(val) {
  return val < 10 ? `0${val}` : val;
}

/**
 * Convert AMPM to lowercase or uppercase letters
 * @param  val
 * @param  token: a: ampm - A: AMPM
 * @return {string}
 */
function formatAMPM(val, token) {
  if (typeof val === 'string') {
    val = token === 'a' ? val.toLowerCase() : val.toUpperCase();
  }
  return val;
}

/**
 * Convert ISO string to date object
 * @param ISODate
 * @returns {Date}
 */
export function isotoDateObj(ISODate) {
  try {
    if (ISODate instanceof Date) {
      return ISODate;
    }

    // parse the ISO date string to convert into date object
    if (ISODate === '') {
      throw new Error('No ISODate provided');
    }
    const fullYear = parseInt(ISODate.substr(0, 4), 10);
    const month = parseInt(ISODate.substr(5, 2), 10);
    const day = parseInt(ISODate.substr(8, 2), 10);
    const hour24 = parseInt(ISODate.substr(11, 2), 10) || 0;
    const minute = parseInt(ISODate.substr(14, 2), 10) || 0;
    const second = parseInt(ISODate.substr(17, 2), 10) || 0;

    return new Date(fullYear, month - 1, day, hour24, minute, second);
  } catch (err) {
    return new Date();
  }
}

/**
 * Creates a formatted date string
 *
 * @param t
 * @param translationKey
 * @param date
 * @param tmznAbbr
 * @param format
 */
export function formatDate({
  date,
  format,
  tmznAbbr,
  t,
  dateTranslationNamespace,
}) {
  const formattedDateObject = date instanceof Date ? date : isotoDateObj(date);

  // we can't use Date.parse
  // because in Safari Date.parse('2017-02-12T07:00:00-0500') == NaN
  // probably we should implement better date parsing
  if (!formattedDateObject) {
    return '--';
  }

  // get individual values
  const dayOfWeekIndex = formattedDateObject.getDay();
  const month = formattedDateObject.getMonth() + 1;

  // get translated values for day of week and month names using t function
  const fullMonthArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const shortMonthArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const fullDayOfWeekArray = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const shortDayOfWeekArray = [
    'sun',
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
  ];

  const fullMonth = fullMonthArray
    && t(dateTranslationNamespace, (fullMonthArray[month - 1]).toLowerCase());
  const shortMonth = shortMonthArray
    && t(dateTranslationNamespace, (shortMonthArray[month - 1]).toLowerCase());
  const fullDayOfWeek = fullDayOfWeekArray && fullDayOfWeekArray[dayOfWeekIndex];
  const shortDayOfWeek = shortDayOfWeekArray && shortDayOfWeekArray[dayOfWeekIndex];
  const formatArray = getFormatParts(format);

  // function to pick individual parts of the date format
  function getFormatVal(part) {
    let val; // value

    let hour; // hour value for the date

    switch (part) {
      case 'yyyy': // 4 digit representation of year (e.g. AD 1 => 0001, AD 2010 => 2010)
        val = formattedDateObject.getFullYear();
        break;
      case 'yy': // 2 digit representation of year, padded (00-99). (e.g. AD 2001 => 01)
        val = formattedDateObject.getFullYear().substr(-2);
        break;
      case 'MMMM': // Month in year (January-December)
        val = fullMonth;
        break;
      case 'MMM': // Month in year (Jan-Dec)
        val = shortMonth;
        break;
      case 'MM': // Month in year, padded (1-12)
        val = month;
        break;
      case 'M': // Month in year, padded (1-12)
        val = month;
        break;
      case 'EEEE': // Day in Week,(Sunday-Saturday)
        val = fullDayOfWeek;
        break;
      case 'EEE': // Day in Week, (Sun-Sat)
        val = shortDayOfWeek ? capitalizeFirstLetter(shortDayOfWeek) : '';
        break;
      case 'dd': // Day in month, padded (01-31)
        val = addLeadingZero(formattedDateObject.getDate());
        break;
      case 'd': // Day in month (1-31)
        val = formattedDateObject.getDate();
        break;
      case 'hh': // Hour in AM/PM, padded (01-12)
        hour = formattedDateObject.getHours();
        val = 12;
        if (hour !== 0) {
          val = hour > 12 ? addLeadingZero(hour - 12) : addLeadingZero(hour);
        }
        break;
      case 'h': // Hour in AM/PM, (1-12)
        hour = formattedDateObject.getHours();
        val = 12;
        if (hour !== 0) {
          val = hour > 12 ? hour - 12 : hour;
        }
        break;
      case 'HH': // Hour in day, padded (00-23)
        val = addLeadingZero(formattedDateObject.getHours());
        break;
      case 'H': // Hour in day (0-23)
        hour = formattedDateObject.getHours();
        val = hour;
        break;
      case 'mm': // Minute in hour, padded (00-59)
        val = addLeadingZero(formattedDateObject.getMinutes());
        break;
      case 'm': // Minute in hour, padded (00-59)
        val = formattedDateObject.getMinutes();
        break;
      case 'a':
        hour = formattedDateObject.getHours();
        val = formatAMPM(hour >= 12 ? 'pm' : 'am', 'a');
        break;
      case 'A':
        hour = formattedDateObject.getHours();
        val = formatAMPM(hour >= 12 ? 'PM' : 'AM', 'A');
        break;
      case 'z':
        val = tmznAbbr || '';
        break;
      default:
        val = part;
    }
    val = typeof val !== 'undefined' ? val : part;
    return val;
  }
  let dateVal = '';

  formatArray.forEach((part) => {
    dateVal += getFormatVal(part);
  });

  return dateVal;
}

export function calendarMonthDateParts(year, month) {
  const firstDayOfMonth = new Date(year, month, 1, 0, 0, 0);
  const tmpFirst = new Date(firstDayOfMonth);

  tmpFirst.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDay());

  if (month === 11) {
    year++;
    month = 0;
  } else {
    month++;
  }

  const lastDayOfMonth = new Date(year, month, 0, 0, 0, 0);
  const tmpLast = new Date(lastDayOfMonth);

  tmpLast.setDate(lastDayOfMonth.getDate() - lastDayOfMonth.getDay() + 6);

  return {
    firstDay: firstDayOfMonth,
    calFirstDay: tmpFirst,
    lastDay: lastDayOfMonth,
    calLastDay: tmpLast,
  };
}

export function distanceBetweenDays(firstDay, secondDay) {
  return Math.round(Math.abs((firstDay.getTime() - secondDay.getTime()) / MS_PER_DAY));
}

export const roundUpHour = (date) => (
  new Date(Math.ceil(date.getTime() / MS_PER_HR) * MS_PER_HR)
);

export const roundDownHour = (date) => (
  new Date(Math.floor(date.getTime() / MS_PER_HR) * MS_PER_HR)
);

export const formatDateWithOffset = ({
  dateStr,
  t,
  dateTranslationNamespace,
  format,
}) => {
  const dateStrInSec = (Date.now() - new Date(dateStr).getTime()) / 1000;

  if (dateStrInSec > SEC_PER_WK) {
    return formatDate({
      date: dateStr,
      format: format || 'MMMM dd, yyyy',
      t,
      dateTranslationNamespace,
    });
  }
  if (dateStrInSec > SEC_PER_DAY) {
    return t(dateTranslationNamespace, 'daysAgo', {
      templateArgs: {
        numOfDays: Math.ceil(dateStrInSec / SEC_PER_DAY),
      },
    });
  }
  if (dateStrInSec > SEC_PER_HR) {
    return t(dateTranslationNamespace, 'hoursAgo', {
      templateArgs: {
        numOfHours: Math.ceil(dateStrInSec / SEC_PER_HR),
      },
    });
  }

  return t(dateTranslationNamespace, 'lessThanAnHourAgo');
};

export const compareDates = (firstDate, secondDate, typeComparision) => {
  const firstTime = isotoDateObj(firstDate).getTime();
  const secondTime = isotoDateObj(secondDate).getTime();

  switch (typeComparision) {
    case 'isBefore':
      return firstTime < secondTime;
    case 'isAfter':
      return firstTime > secondTime;
    case 'isEqual':
      return firstTime === secondTime;
    default:
      return null;
  }
};

export const isSameDay = (milestone, comparisonDate) => {
  if (!milestone || !comparisonDate) {
    return false;
  }

  const now = isotoDateObj(milestone);

  if (now) {
    const dateToCompare = isotoDateObj(comparisonDate);
    const currentTime = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
    const comparisonTime = new Date(
      dateToCompare.getFullYear(),
      dateToCompare.getMonth(),
      dateToCompare.getDate(),
    ).getTime();

    return currentTime === comparisonTime;
  }

  return false;
};

/**
 * Get current numeric day of the month with a leading zero
 */
export const getCurrentDayOfMonth = () => {
  const now = new Date();

  return addLeadingZero(now.getDate());
};

/**
 * Get current month of the year with a leading zero
 */
export const getCurrentMonth = () => {
  const now = new Date();

  return addLeadingZero(now.getMonth() + 1);
};

/**
 * Get current 4 digit year
 */
export const getCurrentYear = () => {
  const now = new Date();

  return now.getFullYear();
};

/**
 * Get an object containing a cross-browser compatible date object and a locale-based formatted date string
 * @param  {string} locale String represting the locale of the current session, e.g. `"en-US"`
 * @param  {string} timeZone String represting the timeZone of the current location, e.g. `"Europe/London"`
 * @param  {string} date Date string, e.g. `"2019-06-10T18:00:00-0400"`
 * @param  {object} options Optional settings to format time as needed, e.g. `{hour: numeric, minutes: numeric}`
 * @return {object} Return date object and formatted date/time string
 */
export function getLocalizedTime(locale, timeZone, date, options) {
  // Get a cross-browser-compatible date string
  const cDate = new Date(getNormalizedDateString(date));

  // Add timeZone to formatting options
  options.timeZone = timeZone || 'Etc/UTC';

  // 12 hour time format is false by default
  options.hour12 = false;

  // Use 12hour time format if locale is in US
  if (locale === ('en-US' || 'es-US')) {
    options.hour12 = true;
  }

  // Create the formatted date string, ex: 4:53 AM
  const formattedTime = new Intl
    .DateTimeFormat(locale, options)
    .format(cDate);

  // Return an object containing the new dateObject and the formatted time
  const obj = {
    dateTime: cDate,
    formattedTime,
  };

  return obj;
}


export function newCompatibleDate(dateString) {
  const normDate = getNormalizedDateString(dateString);

  return new Date(normDate);
}
