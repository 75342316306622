export const getCompatibleTimezone = (timeZone) => {
  const now = new Date();

  try {
    new Intl.DateTimeFormat('en-US', {
      timeZone,
    }).format(now);

    return timeZone;
  } catch {
    return 'Etc/UTC';
  }
};
