import { polyfillToJSON } from './polyfillToJSON';

const entryType = 'measure';

/**
 * Measures the time in milliseconds between two performance marks and returns the measurement data.
 *
 * @param   {string}                    name      Name of measurement
 * @param   {string}                    startMark The mark name to measure against
 * @return  {Promise<PerformanceEntry>}
 */
export function measure(name, startMark) {
  if (!name || !startMark) return null;

  return new Promise((resolve, reject) => {
    const endMark = name;

    /**
     * @type {PerformanceObserverCallback}
     */
    function measureObserverCallback(list, observer) {
      const performanceEntries = list.getEntriesByName(name, entryType);

      if (!Array.isArray(performanceEntries) || !performanceEntries.length) return null;

      // We only care about the first entry
      const [entry] = performanceEntries;

      // Cleanup
      observer.disconnect();

      const polyfilledEntry = polyfillToJSON(entry);

      return resolve(polyfilledEntry);
    }

    try {
      const performanceObserver = new PerformanceObserver(measureObserverCallback);

      performanceObserver.observe({ entryTypes: [entryType] });

      // Mark and measure current `name`
      performance.mark(endMark);
      performance.measure(name, startMark, endMark);
    } catch (err) {
      reject(err);
    }
  });
}
