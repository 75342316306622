declare global {
  interface Window {
    ineum: (...args) => void;
  }
}

/**
 * Send a Custom Event to Instana.
 * https://www.ibm.com/docs/en/instana-observability/current?topic=websites-javascript-agent-api#reporting-custom-events
 */
export function sendInstanaEvent(event: PerformanceEntry | string, meta = {}) {
  try {
    let name: string;
    let duration: number;
    let timestamp: number;

    if (typeof event === 'string') {
      // if we are given a string (event name) instead of a performance entry
      // then treat it how eum.min.js would treat a performance mark
      name = event;
      duration = window.performance.now();
      timestamp = window.performance.timeOrigin;
    } else {
      // otherwise, use the same duration/timestamp as eum.min.js would
      name = event.name;
      if (event.entryType !== 'mark') {
        duration = event.duration;
        timestamp = window.performance.timeOrigin + event.startTime;
      } else {
        duration = event.startTime;
        timestamp = window.performance.timeOrigin;
      }
    }

    window.ineum('reportEvent', name, {
      duration: Math.round(duration),
      timestamp: Math.round(timestamp),
      meta: {
        ...meta,
      },
    });
  } catch (e) {
    //
  }
}
