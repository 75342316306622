// STATE DATA KEYS
export const CONSENT = 'consent';
export const FEATURE_FLAGS = 'featureFlags';
export const ESSENTIAL_TECHNOLOGY = 'essential-technology';
export const FUNCTIONAL_TECHNOLOGY = 'functional-technology';
export const GEOGRAPHICALLY_RELEVANT_ADVERTISING = 'geographically-relevant-advertising';

// FEATURE FLAGS KEYS
export const DPR_AIRLYTICS_FULL_METRICS = 'airlyticsFullMetrics';
export const DPR_AMAZON = 'amazon';
export const DPR_BURDA_AD_ENGINE = 'burdaAdEngine';
export const DPR_BURDA_HEADER_BIDDERS = 'burdaHeaderBidders';
export const DPR_COMSCORE = 'comscore';
export const DPR_CRITEO_SLOT = 'criteoSlot';
export const DPR_CUST_PARAMS_PII = 'custParamsPii';
export const DPR_FACEBOOK_WXNODE = 'facebookWxNode';
export const DPR_FIND_ME = 'findMe';
export const DPR_GEO_IP = 'geoIP';
export const DPR_GOOGLE_NON_LIMITED_ADS = 'googleNonLimitedAds';
export const DPR_GPT = 'gpt';
export const DPR_GPT_PII_GDPR = 'gptPiiGDPR';
export const DPR_GPT_PII_USA = 'gptPiiSaleUSA';
export const DPR_INDEX_EXCHANGE = 'indexExchange';
export const DPR_INSTAGRAM_WXNODE = 'instagramWxNode';
export const DPR_PRISMA_GPT = 'gptPrisma';
export const DPR_HEADER_BIDDERS = 'headerBidders';
export const DPR_MEDALLIA = 'medallia';
export const DPR_MPARTICLE_FULL_METRICS = 'mParticleFullMetrics';
export const DPR_NIELSEN = 'nielsen';
export const DPR_OUTBRAIN = 'outbrain';
export const DPR_RUBICON = 'rubicon';
export const DPR_SOCIAL_LINKS_FOOTER = 'socialLinksFooter';
export const DPR_TABOOLA = 'taboola';
export const DPR_TWITTER_WXNODE = 'twitterWxNode';
export const DPR_YOUTUBE_WXNODE = 'youtubeWxNode';
export const DPR_WFX_TRIGGERS = 'wfxTriggers';

// REDUCER & ACTIONS
export const SET_CONSENT = 'WXU/DPR/SET_CONSENT';
export const SET_FEATURE_FLAGS = 'WXU/DPR/SET_FEATURE_FLAGS';

export const REDUCER_KEY = 'dpr';

export const INITIAL_STATE = {
  [CONSENT]: {},
  [FEATURE_FLAGS]: {},
};
