/**
 * Returns a reproducible id for a module's dom node container,
 * specifically for use in targeting the appropriate element for
 * client-side rehydration of React/Inferno components.
 *
 * @param {Object} idProps
 * @param {string} idProps.region
 * @param {string} idProps.name
 * @param {string} idProps.uuid
 * @param {string} idProps.anchorName
 * @returns {string}
 */
export function getContainerId(idProps) {
  const { anchorName, ...otherProps } = idProps;

  if (anchorName) return anchorName;

  const keys = Object.keys(otherProps).sort();
  const params = keys.map(key => otherProps[key]);

  return params.join('-');
}
