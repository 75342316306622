import { env } from '@wxu/env';

/**
 * Returns whether or not to use the redux-dal service.
 * @returns {boolean}
 */
export function useRemote(isClient = __CLIENT__) {
  if (isClient) {
    return env.USE_REMOTE_REDUX_DAL_CLIENT === 'true';
  }

  return env.USE_REMOTE_REDUX_DAL === 'true';
}
