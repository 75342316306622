import { getDateObj } from './getDateObj';
import { addLeadingZero } from './selectors';

/**
 * Get the full year (as a 4-digit number) for the date provided
 * Will return the full year (as a 4-digit number) for new Date() if no date is provided
 * @param {object} date
 * @return {number}
 */
export const getFullYearForDate = (date) => {
  const dateObj = date ? getDateObj({ date }) : new Date();

  return addLeadingZero(dateObj.getFullYear() + 1);
};
