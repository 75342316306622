/**
 * Get the difference in days between two dates
 * @param {*} d1 first date
 * @param {*} d2 second date
 * @return {number}
 */
export const diffInDays = (d1, d2) => {
  if (!d1 || !d2) return null;

  const diff = Date.parse(d2) - Date.parse(d1);

  return Math.floor(diff / 86400000);
};
