export const INITIAL_STATE = {
  custParamsComplete: false,
  adCallComplete: false,
  targetingReady: false,
};
export const REDUCER_KEY = 'ads';
export const DISPLAY_LAZY_LOADED_AD = 'WXU/ADS/DISPLAY_LAZY_LOADED_AD';
export const requestManager = {
  atf: {
    adserverRequestSent: false,
    amzn: false,
    criteoSlot: false,
    prebid: false,
    posNotToRefresh: [],
  },
  btf: {
    adserverRequestSent: false,
    amzn: false,
    criteoSlot: false,
    prebid: false,
    posNotToRefresh: [],
  },
};
export const AD_CALL = 'AD_CALL';
export const AD_CALL_BTF = 'AD_CALL_BTF';
export const AD_SEQUENTIAL_LOAD_EVENT = 'slotRenderEnded';
export const RUBICON_WRAPPER_LOADED = 'WXU/ADS/RUBICON_WRAPPER_LOADED';
export const RUBICON_INITIALIZED = 'WXU/ADS/RUBICON_INITIALIZED';
export const RUBICON_DONE = 'WXU/ADS/RUBICON_DONE';
export const BTF_BID_TARGETING_DONE = 'WXU/ADS/BTF_BID_TARGETING_DONE';
export const RUBICON_NO_WRAPPER = 'WXU/ADS/RUBICON_NO_WRAPPER';
export const REFRESH_ADS = 'WXU/ADS/REFRESH_ADS';
export const HANDLE_ARTICLE_COLLECTION_ADS = 'WXU/ADS/HANDLE_ARTICLE_COLLECTION_ADS';
export const HANDLE_MISSING_VIDEO_COMPANION = 'WXU/ADS/HANDLE_MISSING_VIDEO_COMPANION';
export const ADS_NEW_THREAD_DELAY = 100;
export const ADS_BIDDERS_PREP_TIMEOUT = 1000;
export const ADS_BIDDERS_AMZNHDBID_LOADED = 'WXU/ADS/ADS_BIDDERS_AMZNHDBID_LOADED';
export const SLOTS_READY = 'WXU/ADS/SLOTS/READY';
export const SET_TARGETING_READY = 'WXU/ADS/SET_TARGETING_READY';
export const SET_TARGETING_CUST_PARAMS = 'WXU/ADS/SET_TARGETING_CUST_PARAMS';
export const COMPLETE_TARGETING_FOR_CUST_PARAMS = 'WXU/ADS/COMPLETE_TARGETING_FOR_CUST_PARAMS';
export const CUST_PARAMS_THIRD_PARTY_PREPARES_DONE = 'WXU/ADS/CUST_PARAMS_THIRD_PARTY_PREPARES_DONE';
export const SET_ALL_CUST_PARAMS = 'WXU/ADS/SET_ALL_CUST_PARAMS';
export const SLOTS_READY_DEPENDENT_THIRD_PARTY_PREPARES_DONE = 'WXU/ADS/SLOTS_READY_DEPENDENT_THIRD_PARTY_PREPARES_DONE';
export const LOAD_AMAZON_HDBID = 'WXU/ADS/LOAD_AMAZON_HDBID';
export const SET_AMAZON_HDBID_SLOTS = 'WXU/ADS/SET_AMAZON_HDBID_SLOTS';
export const SET_AMAZON_SLOTS_CUST_PARAMS = 'WXU/ADS/SET_AMAZON_SLOTS_CUST_PARAMS';
export const SET_CRITEO = 'WXU/ADS/SET_CRITEO';
export const SET_LOTAME = 'WXU/ADS/SET_LOTAME';
export const SET_CRITEO_SLOTS = 'WXU/ADS/SET_CRITEO_SLOTS';
export const LOAD_CRITEO_SLOTS = 'WXU/ADS/LOAD_CRITEO_SLOTS';
export const SET_IASPET_SLOTS = 'WXU/ADS/SET_IASPET_SLOTS';
export const LOAD_INDEX_EXCHANGE = 'WXU/ADS/LOAD_INDEX_EXCHANGE';
export const SET_MISC_CUST_PARAMS = 'WXU/ADS/SET_MISC_CUST_PARAMS';
export const SET_PREBID = 'WXU/ADS/SET_PREBID';
export const SET_WX_AD_TARGETING = 'WXU/ADS/SET_WX_AD_TARGETING';
export const SET_WFXTG_TARGETING = 'WXU/ADS/SET_WFXTG_TARGETING';
export const SET_LOTAME_TARGETING = 'WXU/ADS/SET_LOTAME_TARGETING';
export const AD_BLOCKER = 'adBlocker';
export const MAIN_SLOT = 'MW_Position1';
export const NO_AD_CALL_EXCLUSION = 'WXU/ADS/NO_AD_CALL_EXCLUSION';
export const FETCH_REMOTE_DAL_REQUEST = 'WXU/ADS/FETCH_REMOTE_DAL_REQUEST';
export const SET_HASHED_MPID = 'WXU/ADS/SET_HASHED_MPID';
export const EXCLUDED_LANGUAGES = [
  'fa',
  'tl',
  'id',
];
export const EXCLUDED_COUNTRIES = [
  'CU',
  'IR',
  'SD',
  'SY',
  'UA',
];
export const CUST_PARAMS_THIRD_PARTIES = [
  'amazonSlots',
  'criteo',
  'miscCustParams',
  'wfxtg',
  'wxAdTargeting',
];
export const SLOTS_READY_DEPENDENT_THIRD_PARTIES = [
  'iasPetSlot',
  'rubicon',
];
export const REFRESH_THIRD_PARTIES = [
  'refreshAds',
  'userActionAdRefresh',
];

export const DEFAULT_MW_EMBEDDED_AD_POSITION_1 = 'MW_Position2';
export const DEFAULT_MW_EMBEDDED_AD_POSITION_2 = 'MW_Position3';
export const DEFAULT_MW_EMBEDDED_AD_POSITION_3 = 'Taboola';

export const STANDARD_GPT_LIBRARY = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
export const COOKIELESS_GPT_LIBRARY = 'https://pagead2.googlesyndication.com/tag/js/gpt.js';
