import { getRawDateOffset } from './getDateOffset';
import { getDateObj } from './getDateObj';
import { getNormalizedDateString } from './compatibleTzOffsetDate';

/**
 * Add a zero to the front of a number if the length of the number is 1
 * @param  {number || string} val value to append the zero to
 * @return {string} a value with a zero append to it
 */
const padZero = (val) => {
  if (val.toString().length === 1) return `0${val}`;

  return val;
};

/**
 * Create an ISO date string from a date object
 * @param {Object} options
 * @param {string} options.date date string from api response
 * @param {boolean} [options.resetToStartOfHour]
 * @param {boolean} [options.resetToStartOfDay]
 * @param {boolean} [options.resetToSpecificHour]
 * @param {boolean} [options.resetToOffset]
 * @param {boolean} [options.resetToStartOfMonth]
 * @param {boolean} [options.resetToEndOfMonth]
 * @param {boolean} [options.resetToStartOfWeek]
 * @param {boolean} [options.resetToEndOfWeek]
 * @param {number} [options.minutesFromDate]
 * @param {number} [options.daysFromDate]
 * @param {number} [options.daysBeforeDate]
 * @param {number} [options.monthsBeforeDate]
 * @param {number} [options.monthsAfterDate]
 * @return {string} ISO date sring. Ex: 2019-08-26T15:48:15+04:00
 */
export const getISODateString = ({
  date,
  resetToStartOfHour = false,
  resetToStartOfDay,
  resetToSpecificHour,
  resetToOffset,
  resetToStartOfMonth,
  resetToEndOfMonth,
  resetToStartOfWeek,
  resetToEndOfWeek,
  minutesFromDate,
  daysFromDate,
  daysBeforeDate,
  monthsBeforeDate,
  monthsAfterDate,
}) => {
  if (!date) return '';
  const compatibleDate = getNormalizedDateString(date);
  const offset = getRawDateOffset(compatibleDate);
  const dateObj = getDateObj({ date: compatibleDate });

  if (minutesFromDate) {
    dateObj.setMinutes(dateObj.getMinutes() + +minutesFromDate);
  }

  // Use daysFromDate to create a date object for another date
  if (daysFromDate) {
    dateObj.setDate(dateObj.getDate() + +daysFromDate);
  }

  if (daysBeforeDate) {
    dateObj.setDate(dateObj.getDate() - daysBeforeDate);
  }

  if (monthsBeforeDate) {
    dateObj.setDate(dateObj.getDate() - monthsBeforeDate);
  }

  if (monthsAfterDate) {
    dateObj.setDate(dateObj.getDate() + monthsAfterDate);
  }

  // Sometimes we need to compare dates based on the start of the hour
  if (resetToStartOfHour) {
    dateObj.setMinutes(0);
    dateObj.setSeconds(0);
  }

  // Sometimes we need to compare dates based on the start of the hour
  if (resetToSpecificHour) {
    dateObj.setHours(resetToSpecificHour);
    dateObj.setMinutes(0);
    dateObj.setSeconds(0);
  }

  // Sometimes we need to compare dates based on the start of the hour
  if (resetToStartOfDay) {
    dateObj.setHours(0);
    dateObj.setMinutes(0);
    dateObj.setSeconds(0);
  }

  // Sometimes we need to compare dates based on the minutes in the offset
  if (resetToOffset) {
    const offsetSplit = offset.split(':');

    dateObj.setMinutes(offsetSplit[1]);
    dateObj.setSeconds(0);
  }

  // Resets to the start of the month for the given date
  if (resetToStartOfMonth) {
    dateObj.setDate(1);
  }

  // Resets to the end of the month for the given date
  if (resetToEndOfMonth) {
    const month = dateObj.getMonth();
    const year = dateObj.getFullYear();

    dateObj.setDate(0);
    dateObj.setMonth(month);
    dateObj.setFullYear(year);
  }

  // Resets to the start of the week for the given date
  if (resetToStartOfWeek) {
    dateObj.setDate(dateObj.getDate() - dateObj.getDay());
  }

  // Resets to the end of the week for the given date
  if (resetToEndOfWeek) {
    dateObj.setDate(dateObj.getDate() - (dateObj.getDay()) + 6);
  }

  const hour = padZero(dateObj.getHours());
  const minutes = padZero(dateObj.getMinutes());
  const seconds = padZero(dateObj.getSeconds());
  const year = dateObj.getFullYear();
  const day = padZero(dateObj.getDate());
  const month = padZero(dateObj.getMonth() + 1);
  const dateStr = `${year}-${month}-${day}T${hour}:${minutes}:${seconds}${offset}`;

  return dateStr;
};
