/**
 * Ensures the `toJSON()` method is made available, regardless of support in runtime.
 * Notably, Node.js and Internet Explorer do not support `toJSON()`.
 * @param {PerformanceEntry} entry
 * @returns {PerformanceEntry}
 */
export function polyfillToJSON(entry) {
  if (entry.toJSON) return entry;

  const properties = ['name', 'entryType', 'startTime', 'duration'];

  entry.toJSON = () => properties.reduce((json, property) => {
    if (property in entry) {
      json[property] = entry[property];
    }

    return json;
  }, {});

  return entry;
}
