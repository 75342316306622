// Only export the client loader for the web. No need to import server code.
export const contexts = {
  ads: () => import(/* webpackChunkName: "moneytree" */'@wxu/contexts/src/moneytree/client.loader').then(module => module.clientLoader),
  articles: () => import(/* webpackChunkName: "articles" */'@wxu/contexts/src/articles/client.loader').then(module => module.clientLoader),
  dpr: () => import(/* webpackChunkName: "dpr" */'@wxu/contexts/src/dpr/client.loader').then(module => module.clientLoader),
  metrics: () => import(/* webpackChunkName: "metrics" */'@wxu/contexts/src/metrics/client.loader').then(module => module.clientLoader),
  page: () => import(/* webpackChunkName: "page" */'@wxu/contexts/src/page/client.loader').then(module => module.clientLoader),
  partner: () => import(/* webpackChunkName: "partner" */'@wxu/contexts/src/partner/client.loader').then(module => module.clientLoader),
  user: () => import(/* webpackChunkName: "user" */'@wxu/contexts/src/user/client.loader').then(module => module.clientLoader),
  userDashboard: () => import(/* webpackChunkName: "userDashboard" */'@wxu/contexts/src/user-dashboard/client.loader').then(module => module.clientLoader),
  userSubscription: () => import(/* webpackChunkName: "user" */'@wxu/contexts/src/user-subscription/client.loader').then(module => module.clientLoader),
  weatherMode: () => import(/* webpackChunkName: "weatherMode" */'@wxu/contexts/src/weather-mode/client.loader').then(module => module.clientLoader),
  video: () => import(/* webpackChunkName: "video" */'@wxu/contexts/src/video/client.loader').then(module => module.clientLoader),
  subscription: () => import(/* webpackChunkName: "subscription" */'@wxu/contexts/src/subscription/client.loader').then(module => module.clientLoader),
  subscriptionManagement: () => import(/* webpackChunkName: "subscriptionManagement" */'@wxu/contexts/src/subscriptionManagement/client.loader').then(module => module.clientLoader),
  cookielessRedirect: () => import(/* webpackChunkName: "cookielessRedirect" */'@wxu/contexts/src/cookieless-redirect/client.loader').then(module => module.clientLoader),
  loginRedirect: () => import(/* webpackChunkName: "loginRedirect" */'@wxu/contexts/src/login-redirect/client.loader').then(module => module.clientLoader),
  loggedInRedirect: () => import(/* webpackChunkName: "loggedInRedirect" */'@wxu/contexts/src/loggedIn-redirect/client.loader').then(module => module.clientLoader),
  loggedInLogOut: () => import(/* webpackChunkName: "loggedInLogOut" */'@wxu/contexts/src/loggedIn-logOut/client.loader').then(module => module.clientLoader),
  brazeSDK: () => import(/* webpackChunkName: "brazeSDK" */'@wxu/contexts/src/braze-sdk/client.loader').then(module => module.clientLoader),
  zuoraSubscription: () => import(/* webpackChunkName: "zuoraSubscription" */'@wxu/contexts/src/zuora-subscription/client.loader').then(module => module.clientLoader),
  inAppMessages: () => import(/* webpackChunkName: "inAppMessages" */'@wxu/contexts/src/inAppMessages/client.loader').then(module => module.clientLoader),
  socialLogin: () => import(/* webpackChunkName: "socialLogin" */'@wxu/contexts/src/socialLogin/client.loader').then(module => module.clientLoader),
  // APPEND_MODULE
};
