import get from 'lodash/get';
import {
  dalSelector,
  createDalKey,
  createDeepEqualSelector,
} from '@wxu/contexts/src/redux-dal/selectors';
import { createSelector } from 'reselect';
import {
  dsxLocaleSelector,
} from '@wxu/contexts/src/i18n/selectors';
import {
  pageKeySelector,
  pageCollectionIDSelector,
} from '@wxu/contexts/src/page/selectors';

export const collectionIdSelector = createSelector(
  pageCollectionIDSelector,
  (collectionId) => {
    if (!collectionId) return null;

    const path = collectionId.join('/');

    return path;
  }
);

export const collectionSelector = createDeepEqualSelector(
  dalSelector,
  dsxLocaleSelector,
  collectionIdSelector,
  (dalState, language, collectionId) => {
    if (!collectionId) return null;

    const dalKey = createDalKey({ language, collectionId });
    const collection = get(dalState, ['getCMSCollectionsUrlConfig', dalKey, 'data'], {});

    return collection;
  },
);

export const colTitleSelector = createDeepEqualSelector(
  collectionSelector,
  collection => get(collection, 'title', '')
);

export const isCollectionSponsoredSelector = createDeepEqualSelector(
  collectionSelector,
  collection => get(collection, 'sponsored', false)
);

export const collectionAdZoneSelector = createDeepEqualSelector(
  collectionSelector,
  pageKeySelector,
  (collection, pageKey) => {
    if (!collection) {
      return;
    }
    const testKey = (pageKey === 'index') ? 'article' : pageKey;

    if (/article|video/.test(testKey)) {
      return get(collection, ['ad_metrics', testKey, 'zone'], '');
    }
    return '';
  }
);

export const collectionAdMetricsSelector = createDeepEqualSelector(
  collectionSelector,
  pageKeySelector,
  (collection, pageKey) => {
    if (!collection) {
      return;
    }
    const testKey = (pageKey === 'index') ? 'article' : pageKey;

    if (/article|video/.test(testKey)) {
      return get(collection, ['ad_metrics', testKey, 'metrics'], '');
    }
    return '';
  }
);
