import { getNormalizedDateString } from './compatibleTzOffsetDate';

/**
 * Get the date offeset in minutes
 * @param  {object} date original date object
 * @return {number} date offset. Ex: -300
 */
export const getDateOffsetInMinutes = (date) => {
  if (!date) return '+0';
  const compatibleDate = getNormalizedDateString(date);
  const thenDate = new Date(compatibleDate);
  const then = Number.isNaN(thenDate.getTime()) ? new Date() : thenDate; // thenDate could be Invalid Date
  const minusIndex = compatibleDate.lastIndexOf('-');
  const plusIndex = compatibleDate.lastIndexOf('+');
  const endingIndex = 6;

  let offset = '';
  let hours = 0;
  let minutes = 0;

  // If plus or minus sign is present
  if (plusIndex > -1 || minusIndex > -1) {
    const signIndex = plusIndex > -1 ? plusIndex : minusIndex;

    // Get just the offset from the dateStr
    offset = compatibleDate.substring(signIndex, signIndex + endingIndex);

    [hours, minutes] = offset.split(':');

    // Convert hour/minute strings to numbers
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);
  }

  // Convert the GMT offset to an offset in minutes
  const offsetInMinutes = hours * 60 + (hours < 0 ? (-minutes) : minutes);

  // Final offset value must have its sign flipped
  // (╯°□°)╯︵ ┻━┻
  return (offsetInMinutes + then.getTimezoneOffset()) * -1;
};

/**
 * Get the raw date offset
 * Ex: -05:00
 * @param  {string} date original date string
 * @return {string} offset
 */
export const getRawDateOffset = (date) => {
  if (!date) return undefined;

  const compatibleDate = getNormalizedDateString(date);
  const endingIndex = 6;
  const minusIndex = compatibleDate.lastIndexOf('-');
  const plusIndex = compatibleDate.lastIndexOf('+');

  let offset = '+00:00';

  // If plus or minus sign is present
  if (plusIndex > -1 || minusIndex > -1) {
    const signIndex = plusIndex > -1 ? plusIndex : minusIndex;

    // Get just the offset from the dateStr
    offset = compatibleDate.substring(signIndex, signIndex + endingIndex);
  }

  return offset;
};

/**
 * Get the date offset in hours
 * Ex: -5
 * @param  {string} date original date string
 * @return {number} offset
 */
export const getDateOffsetInHours = (date) => {
  if (!date) return undefined;

  const rawOffset = getRawDateOffset(date);
  const offsetStr = rawOffset.replace(':', '');

  return (parseInt(offsetStr, 10) || 0) / 100;
};
