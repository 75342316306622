import { useRemote } from './useRemote';

/**
 * @param {import('@wxu/module-interface').ModuleInterface} moduleInterface
 */
export async function clientLoader(moduleInterface) {
  let loader = () => null;

  // Use redux-dal service
  if (useRemote()) {
    loader = await import(/* webpackChunkName: "reduxDalClientLoader" */'./remote/client.loader')
      .then(module => module.clientLoader);
    await loader(moduleInterface);
    return;
  }

  // Use redux-dal context directly
  loader = await import(/* webpackChunkName: "reduxDalClientLoader" */'./local/client.loader')
    .then(module => module.clientLoader);
  await loader(moduleInterface);
}
