export const REDUCER_KEY = 'video';
export const INITIAL_STATE = {
  activeVideo: undefined,
  playlists: undefined,
};
export const IASPET_TIMEOUT = 1000;

export const BASE_AD_TAG = '//pubads.g.doubleclick.net/gampad/ads?env=vp&gdfp_req=1&output=xml_vast3&ad_rule=1';
export const VIDEO_DESKTOP_AD_SIZE = '640x480';
export const VIDEO_MOBILE_AD_SIZE = '640x360';

// Action types
export const SAVE_PLAYER_ID = 'WXU/VIDEO/SAVE_PLAYER_ID';
export const SAVE_PLAYLISTS = 'WXU/VIDEO/SAVE_PLAYLISTS';
export const ADD_PLAYLIST = 'WXU/VIDEO/ADD_PLAYLIST';

// JW Player specific action types; these correspond to JW Player events
export const JWP_AD_REQUEST = 'WXU/VIDEO/JWP_AD_REQUEST';
export const JWP_AD_COMPANIONS = 'WXU/VIDEO/JWP_AD_COMPANIONS';
export const JWP_AD_STARTED = 'WXU/VIDEO/JWP_AD_STARTED';
export const JWP_AD_SKIPPED = 'WXU/VIDEO/JWP_AD_SKIPPED';
export const JWP_AD_PAUSE = 'WXU/VIDEO/JWP_AD_PAUSE';
export const JWP_AD_PLAY = 'WXU/VIDEO/JWP_AD_PLAY';
export const JWP_AD_BREAK_START = 'WXU/VIDEO/JWP_AD_BREAK_START';
export const JWP_AD_ERROR = 'WXU/VIDEO/JWP_AD_ERROR';
export const JWP_AD_COMPLETE = 'WXU/VIDEO/JWP_AD_COMPLETE';
export const JWP_AUTOSTART_NOT_ALLOWED = 'WXU/VIDEO/JWP_AUTOSTART_NOT_ALLOWED';
export const JWP_BEFORE_PLAY = 'WXU/VIDEO/JWP_BEFORE_PLAY';
export const JWP_BEFORE_COMPLETE = 'WXU/VIDEO/JWP_BEFORE_COMPLETE';
export const JWP_COMPLETE = 'WXU/VIDEO/JWP_COMPLETE';
export const JWP_FIRST_FRAME = 'WXU/VIDEO/JWP_FIRST_FRAME';
export const JWP_FULLSCREEN = 'WXU/VIDEO/JWP_FULLSCREEN';
export const JWP_PLAY_ATTEMPT = 'WXU/VIDEO/JWP_PLAY_ATTEMPT';
export const JWP_PLAY = 'WXU/VIDEO/JWP_PLAY';
export const JWP_PLAYLIST = 'WXU/VIDEO/JWP_PLAYLIST';
export const JWP_PLAYLIST_ITEM = 'WXU/VIDEO/JWP_PLAYLIST_ITEM';
export const JWP_PAUSE = 'WXU/VIDEO/JWP_PAUSE';
export const JWP_READY = 'WXU/VIDEO/JWP_READY';
export const JWP_TIME = 'WXU/VIDEO/JWP_TIME';
export const JWP_VIEWABLE = 'WXU/VIDEO/JWP_VIEWABLE';
export const JWP_ADS_MANAGER = 'WXU/VIDEO/JWP_ADS_MANAGER';
export const JWP_NEXT_CLICK = 'WXU/VIDEO/JWP_NEXT_CLICK';
export const JWP_DISPLAY_CLICK = 'WXU/VIDEO/JWP_DISPLAY_CLICK';
export const JWP_ERROR = 'WXU/VIDEO/JWP_ERROR';
export const JWP_SETUP_ERROR = 'WXU/VIDEO/JWP_SETUP_ERROR';

// Store Actions
export const SAVE_ACTIVE_VIDEO = 'WXU/VIDEO/SAVE_ACTIVE_VIDEO';
