import { createLogger } from '@wxu/logger';
import { getReduxLogging } from '@wxu/browser-dev-tools';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createLogger as createReduxLogger } from 'redux-logger';
import { makeRootReducer } from './makeRootReducer';
import { registerReducer } from './registerReducer';
import { ReducerStore } from './ReducerStore';

/**
 * @param {Object} INITIAL_STATE
 * @param {Object} options
 * @param {Function[]} options.middleware
 * @param {Function[]} options.enhancers
 * @param {Object} options.history
 * @return {Promise<import('redux').Store>}
 */
export async function createStore(INITIAL_STATE = {}, {
  middleware = [],
  enhancers = [],
  history = null,
  baseReducers = {},
} = {}) {
  let transformedMiddleware;
  const logger = createLogger('redux-saga');
  const reducerStore = new ReducerStore();
  const sagaMiddleware = createSagaMiddleware({
    // Set debug logging on saga due to duplicate stack traces
    logger: (level, ...args) => logger.debug(...args),
    onError: err => (process.env.NODE_ENV !== 'production' ? logger.error(err) : null),
  });

  // if supplied an enhanced history, keep it in sync with redux
  if (history) {
    const {
      historyMiddleware,
      reducer,
      REDUCER_KEY: reduxHistoryReducerKey,
    } = await import(/* webpackChunkName: "reduxHistory" */'@wxu/redux-history');

    reducerStore.add(reduxHistoryReducerKey, reducer);
    transformedMiddleware = [
      sagaMiddleware,
      historyMiddleware(history),
      ...middleware,
    ];
  } else {
    transformedMiddleware = [
      sagaMiddleware,
      ...middleware,
    ];
  }

  const useReduxLogger = await getReduxLogging();

  if (useReduxLogger) {
    transformedMiddleware.push(createReduxLogger({ collapsed: true }));
  }

  const store = configureStore({
    reducer: makeRootReducer(reducerStore, baseReducers, INITIAL_STATE),
    preloadedState: INITIAL_STATE,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      immutableCheck: {
        ignoredPaths: [
          'voice-dialog',
          'dal.getSunV3DailyForecastUrlConfig', // State seems to be getting mutated in voice-dialog
          'dal.getSunV1WWIRByGeocodeUrlConfig', // Don't know where this state is getting mutated,
          'dal.getMewAdThirdPartyConfigsUrlConfig',
          'video.playlists',
          'ads.rubiconDone.requestManager',
          // but if this ignorePath is removed, the video link gets messed up and disappears
        ],
      },
    }).concat(transformedMiddleware),
    enhancers,
    devTools: {
      traceLimit: 100,
    },
  });

  store.reducerStore = reducerStore;
  store.runSaga = sagaMiddleware.run;
  store.registerReducer = (key, reducer) => registerReducer(store, { key, reducer, baseReducers });

  return store;
}
