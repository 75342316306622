import { createSelector } from 'reselect';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import mergeWith from 'lodash/mergeWith';
import uniq from 'lodash/uniq';
import Cookies from 'cookies-js';
import {
  pageKeySelector,
  privacyHeaderSelector,

  deviceClassSelector,
  serverPageStateQuerySelector,
} from '@wxu/contexts/src/page/selectors';
import {
  dalSelector,
  createDalKey,
  createDeepEqualSelector,
} from '@wxu/contexts/src/redux-dal/selectors';
import {
  allowCustParamsPiiSelector,
  allowGptPiiSaleUSASelector,
  isAnUSRegimeSelector,
} from '@wxu/contexts/src/dpr/selectors';
import { subscriptionsEnabledSelector } from '@wxu/contexts/src/subscription/selectors';
import { isUserLoggedInSelector } from '@wxu/contexts/src/user/selectors';
import { partnerSelector } from '@wxu/contexts/src/partner/selectors';
import {
  pageConfigAttributesSelector,
  pageConfigAdsSelector,
  regionsSelector,
  pageCodeSelector,
} from '@wxu/page-config/src/selectors';
import {
  collectionAdZoneSelector,
  collectionAdMetricsSelector,
} from '@wxu/contexts/src/collection/selectors';
import { articleSelector, articleCollectionAdZoneSelector } from '@wxu/contexts/src/articles/selectors';
import { activeVideoSelector } from '@wxu/contexts/src/video/selectors/videoAsset.selectors';
import { preferredLocationPlaceIdSelector } from '@wxu/contexts/src/preferred-location/selectors';
import {
  localeSelector,
} from '@wxu/contexts/src/i18n/selectors';
import { uuid } from '@wxu/uuid';
import { REDUCER_KEY as VIDEO_REDUCER_KEY } from '@wxu/modules/src/VideoWithPlaylist/constants';
import { RESTRICTIVE_REGIMES } from '@wxu/dpr-sdk/src/configs/regimesConfig';
import {
  THIRD_PARTIES,
  PII_CUST_PARAM_KEYS,
  AUDIENCE_CAPTIVATION_PROPS_2_REMOVE,
  RESTRICTED_PREBIDDING_PRELOAD_SCRIPTS,
  INSTANA_PAGE_KVS,
} from './constants';
import {
  REDUCER_KEY,
} from '../constants';

export const slotsReadySelector = state => state?.[REDUCER_KEY]?.slotsReady ?? false;
export const rubiconDoneSelector = state => state?.[REDUCER_KEY]?.rubiconDone ?? false;
export const btfBidTargetingDoneSelector = state => state?.[REDUCER_KEY]?.btfBidTargetingDone ?? false;
export const custParamsCompleteSelector = state => state?.[REDUCER_KEY]?.custParamsComplete
  ?? false;
export const targetingReadySelector = state => state?.[REDUCER_KEY]?.targetingReady ?? false;
export const amznHdBidStatusSelector = state => state?.[REDUCER_KEY]?.amznHdBidStatus ?? false;
export const criteoSlotStatusSelector = state => state?.[REDUCER_KEY]?.criteoSlotStatus ?? false;
export const setWfxtgCompleteSelector = state => state?.[REDUCER_KEY]?.wfxtg ?? false;
export const adCallCompleteSelector = state => state?.[REDUCER_KEY]?.adCallComplete ?? false;
export const btfAdCallCompleteSelector = state => state?.[REDUCER_KEY]?.btfAdCallComplete ?? false;
export const custParamsAndRubiconStatusSelector = createSelector(
  rubiconDoneSelector,
  custParamsCompleteSelector,
  (rubiconDone, custParamsComplete) => ({
    custParamsComplete,
    rubiconDone,
  })
);

export const screenSizeSelector = createSelector(
  deviceClassSelector,
  (deviceClass) => {
    let screenSize = deviceClass;

    // ensure that we do not mix mobile device class with tablet/desktop
    if (__CLIENT__ && deviceClass !== 'mobile') {
      const { screen: { width } } = window;

      if (width > 1024) {
        screenSize = 'desktop';
      } else if (width >= 768) {
        screenSize = 'tablet';
      }
    }
    return screenSize;
  }
);

export const pageAdsSelector = createSelector(
  regionsSelector,
  (regions) => {
    const flatRegions = flatten(Object.values(regions ?? {}));

    return flatRegions.filter(el => (el.component === 'WxuAd' && !el?.props?.disableAd)) ?? [];
  }
);

export const pageAdPositionsSelector = createSelector(
  pageAdsSelector,
  screenSizeSelector,
  (adPlacements, screenSize) => {
    const positions = adPlacements.map(el => {
      if (screenSize === 'tablet' && el.props.tabletId === 'none') {
        return null;
      }
      const position = (screenSize === 'tablet' && el.props.tabletId)
        ? el.props.tabletId : el.props.id;

      return position;
    });
    const finalPositions = positions.filter(el => el !== null);

    return finalPositions.join(',');
  }
);

export const getAdPositionsByScreenSizeSelector = screenSize => createSelector(
  pageAdsSelector,
  (adPlacements) => {
    const positions = adPlacements.map(el => {
      if (screenSize === 'tablet' && el.props.tabletId === 'none') {
        return null;
      }
      const position = (screenSize === 'tablet' && el.props.tabletId)
        ? el.props.tabletId : el.props.id;

      return position;
    });
    const finalPositions = positions.filter(el => el !== null);

    return finalPositions.join(',');
  }
);

// TO-DO: Check with Steven Sherwood, Cord Hamrick, and Lucas
// Cyr to see whether this selector should still exist: It is
// only still used by Lotame; values in page configs are often
// out-of-date; metrics beacon now uses UnitAndZone (below).
export const adsMetricsLevelsSelector = createSelector(
  pageConfigAttributesSelector,
  collectionAdMetricsSelector,
  (pageAttributes, collectionMetricsLevels) => {
    const pageMetricsLevels = pageAttributes?.metrics_levels ?? null;

    return collectionMetricsLevels || pageMetricsLevels;
  },
);

export const pageConfigAdsAttributesSelector = createSelector(
  pageConfigAttributesSelector,
  attributes => attributes?.ads,
);

export const pageAdEnginePartnerSelector = createSelector(
  pageConfigAdsSelector,
  adsAttributes => adsAttributes?.engine,
);

export const pageAdNetworkCodeSelector = createSelector(
  pageConfigAdsSelector,
  adsAttributes => adsAttributes?.networkCode,
);

export const pageAdUnitsSelector = createSelector(
  localeSelector,
  pageConfigAdsSelector,
  partnerSelector,
  (locale, adsAttributes, partner) => {
    const {
      adUnits = {
        mobile: 'mobile_smart', // provides default values
        desktop: 'web_weather', // for pages without
        tablet: 'tablet_weather', // ads present
      },
    } = adsAttributes;

    let { mobile: preMobile, desktop: preDesktop, tablet: preTablet } = adUnits;

    preMobile = preMobile.includes('_us') ? preMobile.substr(0, preMobile.indexOf('_us')) : preMobile;
    preDesktop = preDesktop.includes('_us') ? preDesktop.substr(0, preDesktop.indexOf('_us')) : preDesktop;
    preTablet = preTablet.includes('_us') ? preTablet.substr(0, preTablet.indexOf('_us')) : preTablet;
    let suffix = 'intl';

    switch (locale) {
      case 'en-US':
        suffix = 'us';
        break;
      case 'es-US':
        suffix = 'es_us';
        break;
      case 'en-IN':
        suffix = 'en_in';
        break;
      default:
        break;
    }
    // WEB-26177 OnePlus/Oppo/Transsion adjustment for en-US
    // For Oppo/Transsion adUnit for mobile on en-US should
    // end with _intl. Example: mobile_smart_intl. If suffix
    // is NOT 'es_us' and it is NOT 'en_in' and partner is
    // either oppo_widget or transsion, append intl.
    if ((suffix !== 'es_us' && suffix !== 'en_in')
      && (partner.includes('oppo') || partner.includes('transsion'))) {
      suffix = 'intl';
    }

    const mobile = preMobile.includes('mobile_smart') ? `${preMobile}_${suffix}` : preMobile;
    const desktop = preDesktop.includes('web_weather') ? `${preDesktop}_${suffix}` : preDesktop;
    const tablet = preTablet.includes('tablet_weather') ? `${preTablet}_${suffix}` : preTablet;

    return {
      desktop,
      mobile,
      tablet,
    };
  }
);

export const pageDeviceAdUnitSelector = createSelector(
  screenSizeSelector,
  pageAdUnitsSelector,
  (screenSize, pageAdUnits) => pageAdUnits?.[screenSize] ?? '',
);

export const adTagOverridesSelector = createSelector(
  pageConfigAdsSelector,
  adsAttributes => adsAttributes?.adTagOverrides,
);

export const pageAdZoneSelector = createSelector(
  pageConfigAdsSelector,
  adsAttributes => adsAttributes?.adZone,
);

export const adsMetricsUnitAndZoneSelector = createSelector(
  pageDeviceAdUnitSelector,
  pageAdZoneSelector,
  collectionAdZoneSelector,
  (
    pageDeviceAdUnit,
    pageAdZone,
    collectionAdZone,
  ) => {
    const selectedZone = collectionAdZone || pageAdZone || 'non_monetized';
    const unitAndZone = `${pageDeviceAdUnit}/${selectedZone}`;

    return unitAndZone;
  },
);

export const adsMetricsZoneSelector = createSelector(
  pageConfigAdsSelector,
  adsAttributes => adsAttributes?.adsmetrics_zone,
);

export const adsTestSelector = () => {
  if (__CLIENT__) {
    return Cookies.get('adstest');
  }
};

export const adZoneSelector = createDeepEqualSelector(
  articleCollectionAdZoneSelector,
  collectionAdZoneSelector,
  pageAdZoneSelector,
  (articleCollectionAdZone, collectionAdZone, adZone) => articleCollectionAdZone
    || collectionAdZone || adZone
);

export const nctauSelector = createDeepEqualSelector(
  screenSizeSelector,
  collectionAdZoneSelector,
  pageAdNetworkCodeSelector,
  pageAdUnitsSelector,
  pageAdZoneSelector,
  adsTestSelector,
  (screenSize, collectionAdZone, networkCode, adUnits, adZone, adsTest) => {
    const adUnit = adUnits[screenSize];
    const finalAdZone = collectionAdZone || adZone;

    return `${networkCode}/${adsTest ? 'test_' : ''}${adUnit}/${finalAdZone}`;
  }
);

export const nctauInterstitialSelector = createDeepEqualSelector(
  screenSizeSelector,
  pageAdNetworkCodeSelector,
  pageAdUnitsSelector,
  adsTestSelector,
  (screenSize, networkCode, adUnits, adsTest) => {
    const adUnit = adUnits[screenSize];

    return `${networkCode}/${adsTest ? 'test_' : ''}${adUnit}/interstitial`;
  }
);

export const isUserActionAdRefreshSelector = createDeepEqualSelector(
  deviceClassSelector,
  pageConfigAdsAttributesSelector,
  (deviceClass, adsConfigs) => {
    const thirdParties = [
      ...(adsConfigs?.thirdParties ?? []),
    ];
    const { token: userActionAdRefreshToken } = deviceClass === 'mobile'
      ? THIRD_PARTIES.USER_ACTION_AD_REFRESH
      : THIRD_PARTIES.DESKTOP_USER_ACTION_AD_REFRESH;

    if (thirdParties.includes(userActionAdRefreshToken)) {
      return true;
    }
    return false;
  }
);

export const thirdPartyTokensSelector = createDeepEqualSelector(
  pageConfigAdsAttributesSelector,
  deviceClassSelector,
  subscriptionsEnabledSelector,
  isUserLoggedInSelector,
  (adsConfigs, deviceClass, subscriptionsEnabled, isUserLoggedIn) => {
    // FYI: (Bad design, but maybe too much trouble to fix)
    // Tokens are used to select adThirdPartyConfigs. The constant
    // THIRD_PARTIES.[name].token in most cases is really the type
    // that is returned from getMewAdThirdPartyConfigsUrlConfig,
    // which is different, and can't be used here to select thirdPartyConfigs.
    // So, we explicitly assign these tokens
    const RUBICON_TOKEN = 'rubicon_enUS';

    const SEQUENTIAL_AD_LOADER_TOKEN = 'sequentialAdLoader';
    const AB_TESTING_TOKEN = 'abTesting';
    const TIME_FRAME = THIRD_PARTIES.TIME_FRAME.token;
    const HELIOS_AD_SDK = THIRD_PARTIES.HELIOS_AD_SDK.token;
    const ADMIRAL = THIRD_PARTIES.ADMIRAL.token;
    const IAB_CATEGORIES = THIRD_PARTIES.IAB_CATEGORIES.token;
    const INSTANA = THIRD_PARTIES.INSTANA.token;

    // HOWEVER, These tokens, which are the same as the types from
    // thirdparty configs, can be used to select adThirdPartyConfigs.
    const { token: mobileUserActionAdRefreshToken } = THIRD_PARTIES.USER_ACTION_AD_REFRESH; // mobile
    const { token: mobileTimerAdRefreshToken } = THIRD_PARTIES.MOBILE_REFRESH_ADS;
    const { token: desktopTimerAdRefreshToken } = THIRD_PARTIES.REFRESH_ADS; // desktop
    const { token: desktopUserActionAdRefreshToken } = THIRD_PARTIES.DESKTOP_USER_ACTION_AD_REFRESH;
    let thirdParties = [
      ...(adsConfigs?.thirdParties ?? []),
    ];

    // Always load rubicon, since it manages criteoSlot and amznHdBid
    if (!thirdParties.includes(RUBICON_TOKEN)) {
      thirdParties.push(RUBICON_TOKEN);
    }

    // Always load SEQUENTIAL_AD_LOADER
    if (!thirdParties.includes(SEQUENTIAL_AD_LOADER_TOKEN)) {
      thirdParties.push(SEQUENTIAL_AD_LOADER_TOKEN);
    }

    if (!thirdParties.includes(AB_TESTING_TOKEN)) {
      thirdParties.push(AB_TESTING_TOKEN);
    }

    if (!thirdParties.includes(HELIOS_AD_SDK)) {
      thirdParties.push(HELIOS_AD_SDK);
    }

    if (!thirdParties.includes(THIRD_PARTIES.JANUS.token)) {
      thirdParties.push(THIRD_PARTIES.JANUS.token);
    }

    if (!thirdParties.includes(TIME_FRAME)) {
      thirdParties.push(TIME_FRAME);
    }

    if (!thirdParties.includes(ADMIRAL)) {
      thirdParties.push(ADMIRAL);
    }


    if (!thirdParties.includes(IAB_CATEGORIES)) {
      thirdParties.push(IAB_CATEGORIES);
    }

    if (!thirdParties.includes(INSTANA)) {
      thirdParties.push(INSTANA);
    }

    // Always load both refresh versions.  The correct one
    // for mobile or desktop will be sorted out later
    if (deviceClass === 'mobile') {
      thirdParties.push(mobileUserActionAdRefreshToken);
      thirdParties.push(mobileTimerAdRefreshToken);
    } else {
      thirdParties.push(desktopUserActionAdRefreshToken);
      thirdParties.push(desktopTimerAdRefreshToken);
    }

    if (subscriptionsEnabled && isUserLoggedIn) {
      thirdParties = thirdParties
        .filter((thirdParty) => !thirdParty.includes(THIRD_PARTIES.LOTAME.token));
    }
    return uniq(thirdParties).join(',');
  }
);

/**
 * Returns boolean true if Rubicon config
 * exists in raw adThirdPartyConfigs
 */
export const useRubiconSelector = createDeepEqualSelector(
  pageConfigAdsSelector,
  (adsConfigs) => {
    const thirdParties = [
      ...(adsConfigs?.thirdParties ?? []),
    ];

    return thirdParties.includes('rubicon_enUS');
  }
);

/**
 * Returns an array of the current page's required third-party script sources.
 */
export const thirdPartyScriptsSelector = createDeepEqualSelector(
  thirdPartyTokensSelector,
  privacyHeaderSelector,
  (thirdPartyTokens, regime) => {
    const tokens = thirdPartyTokens.split(',');

    if (!Array.isArray(tokens) || !tokens.length) return [];

    const {
      CRITEO_SLOT,
      IAS_PET_SLOT,
      ...thirdPartiesWithoutCriteoAndIas
    } = THIRD_PARTIES;

    const scripts = tokens
      .filter(filterPrebbidingPreloadScriptsPerRegime(regime))
      .reduce((accum, currToken) => {
        const thirdParties = Object.values(thirdPartiesWithoutCriteoAndIas);
        const matchedThirdParty = thirdParties.find(({ token }) => currToken.includes(token));

        if (matchedThirdParty) {
          const { script } = matchedThirdParty;

          return script ? [...accum, script] : accum;

        }

        return accum;
      }, []);

    return scripts;
  },
);

/**
 *  In restrictive regimes, filter out prebidding scripts from preloading.
 */
const filterPrebbidingPreloadScriptsPerRegime = regime => (
  token
) => !(
  RESTRICTIVE_REGIMES.includes(regime)
  && RESTRICTED_PREBIDDING_PRELOAD_SCRIPTS.some(
    currenScript => token.includes(currenScript)
  )
);

export const createAdConfigsSelector = (fold, screenSize) => createDeepEqualSelector(
  dalSelector,
  screenSizeSelector,
  screenSize ? getAdPositionsByScreenSizeSelector(screenSize) : pageAdPositionsSelector,
  pageAdsSelector,
  useSequentialAdLoaderSelector,
  (dalState, currentScreenSize, positions, adModules, useSequentialAdLoader) => {
    const dalKey = createDalKey({ positions, screenSize: screenSize ?? currentScreenSize });

    let adConfigData = dalState?.getMewAdConfigsUrlConfig?.[dalKey]?.data?.data ?? null;

    if (!adConfigData) {
      return [];
    }


    adConfigData = adConfigData.reduce((acc, curr) => {
      const result = adModules.find(adModule => adModule.props.id === curr.name
        || adModule.props.tabletId === curr.name);

      return [
        ...acc,
        {
          ...curr,
          lazyload: result.props.lazyload,
          disableAd: result?.props?.disableAd,
          sequentialGroup: result?.props?.sequentialGroupOverride || curr?.sequentialGroup || 'btf',
        },
      ];
    }, []);

    if (useSequentialAdLoader && fold) {
      adConfigData = adConfigData.filter(adConfig => adConfig.sequentialGroup === fold);
    }

    return adConfigData;
  }
);

export const wxAdTargetingSelector = createDeepEqualSelector(
  allowCustParamsPiiSelector,
  dalSelector,
  localeSelector,
  preferredLocationPlaceIdSelector,
  (allowCustParamsPii, dalState, locale, placeid) => {
    const dalKey = createDalKey({ locale, placeid });
    const adConfigData = dalState?.getWxAdTargetingUrlConfig?.[dalKey]?.data?.data ?? {};

    // WEB-21715 remove AUDIENCE_CAPTIVATION_PROPS_2_REMOVE from cust_params targeting
    const finalCustParams = omit(adConfigData, AUDIENCE_CAPTIVATION_PROPS_2_REMOVE);

    if (allowCustParamsPii) {
      return finalCustParams;
    }

    return omit(finalCustParams, PII_CUST_PARAM_KEYS);
  },
);

export const adThirdPartyConfigsSelector = createDeepEqualSelector(
  dalSelector,
  thirdPartyTokensSelector,
  (dalState, tokens) => {
    const dalKey = createDalKey({ tokens });
    let thirdPartyConfigs = dalState
      ?.getMewAdThirdPartyConfigsUrlConfig?.[dalKey]?.data?.data ?? [];

    // eslint-disable-next-line camelcase
    thirdPartyConfigs = thirdPartyConfigs.map(({ config, config_type }) => ({
      config,
      type: config_type, // eslint-disable-line camelcase
    }));

    return thirdPartyConfigs;
  },
);

export const amznHdBidConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES.AMAZON_HEADER_BIDDER.token;
        }
        return false;
      });

      if (matchedConfig && matchedConfig.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const admiralConfigSelector = createDeepEqualSelector(
  isAnUSRegimeSelector,
  adThirdPartyConfigsSelector,
  (isUSRegime, thirdPartyConfigs) => {
    if (!isUSRegime) {
      return {};
    }

    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES.ADMIRAL.token;
        }
        return {};
      });

      if (matchedConfig && matchedConfig.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const instanaConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES.INSTANA.token;
        }
        return false;
      });

      if (matchedConfig && matchedConfig.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const amznSlotsConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES.AMAZON_SLOTS.token;
        }
        return false;
      });

      if (matchedConfig && matchedConfig.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const criteoConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES.CRITEO.token;
        }
        return false;
      });

      if (matchedConfig && matchedConfig.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const criteoSlotConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  deviceClassSelector,
  (thirdPartyConfigs, deviceClass) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES.CRITEO_SLOT.token;
        }
        return false;
      });

      if (matchedConfig && matchedConfig.config) {
        matchedConfig.config.placements = matchedConfig.config.placements[deviceClass];
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const geoedgeConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES.GEOEDGE.token;
        }
        return false;
      });

      if (matchedConfig && matchedConfig.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const iasPetSlotConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES.IAS_PET_SLOT.token;
        }
        return false;
      });

      if (matchedConfig && matchedConfig.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const indexExchangeConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES.INDEX_EXCHANGE.token;
        }
        return false;
      });

      if (matchedConfig && matchedConfig.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const lotameConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES.LOTAME.token;
        }
        return false;
      });

      if (matchedConfig && matchedConfig.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

const lotameAccountIDRegex = /[0-9]{4}/;

export const lotameAccountIDSelector = createDeepEqualSelector(
  lotameConfigSelector,
  /**
   * @param {Object} config
   * @return {string}
   */
  (config) => {
    // Use default for pages without either the moneyTree context or
    // lotame configured as a third party.
    const { tagsSrcId = '1884' } = config;
    const id = tagsSrcId.match(lotameAccountIDRegex);

    return id;
  }
);

export const lotameTagSrcSelector = createDeepEqualSelector(
  lotameAccountIDSelector,
  id => (id ? `//tags.crwdcntrl.net/lt/c/${id}/lt.min.js` : ''),
);

export const prebidConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES.PREBID.token;
        }
        return false;
      });

      if (matchedConfig && matchedConfig.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const premiumAdDataLakeConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES.PREMIUM_AD_DATA_LAKE.token;
        }
        return false;
      });

      if (matchedConfig && matchedConfig.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const userActionRefreshAdConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES.USER_ACTION_AD_REFRESH.token;
        }
        return false;
      });

      if (matchedConfig && matchedConfig.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const atfUserActionRefreshAdConfigSelector = createDeepEqualSelector(
  userActionRefreshAdConfigSelector,
  pageKeySelector,
  localeSelector,
  (configs, pageKey, locale) => {
    const {
      atf,
      refreshBidders,
    } = configs;
    const localesInConfigs = Object.keys(atf) || [];
    const locales2Refresh = localesInConfigs.filter(key => key.includes(locale) || key.includes('all')) || [];
    let positionsHolder = configs?.atf?.positions;
    let posToRefresh = locales2Refresh.reduce((acc, cur) => {
      // Collect all positions under locale, pageKey and 'all'
      const pageKeys = Object.keys(atf[cur]);
      const thePageKey = (pageKeys.filter(key => key.includes(pageKey) || key.includes('all')) || [])[0];

      if (thePageKey) {
        positionsHolder = atf?.[cur]?.[thePageKey]?.positions ?? positionsHolder;

        return positionsHolder;
      }

      return acc;
    }, []);

    if (isEmpty(posToRefresh)) {
      posToRefresh = positionsHolder;
    }
    let userActionDelayHolder = configs?.atf?.userActionDelay;
    const userActionDelay = locales2Refresh.reduce((acc, cur) => {
      // Collect all intervals under locale, pageKey and 'all'
      const pageKeys = Object.keys(atf[cur]);
      const thePageKey = (pageKeys.filter(key => key.includes(pageKey) || key.includes('all')) || [])[0];

      if (thePageKey) {
        userActionDelayHolder = atf?.[cur]?.[thePageKey]?.userActionDelay || userActionDelayHolder;
      }
      return userActionDelayHolder;
    }, []);
    let inViewOnlyHolder = configs?.atf?.inViewOnly;
    const inViewOnly = locales2Refresh.reduce((acc, cur) => {
      // Collect all intervals under locale, pageKey and 'all'
      const pageKeys = Object.keys(atf[cur]);
      const thePageKey = (pageKeys.filter(key => key.includes(pageKey) || key.includes('all')) || [])[0];

      if (thePageKey) {
        inViewOnlyHolder = atf?.[cur]?.[thePageKey]?.inViewOnly ?? inViewOnlyHolder;
      }
      return inViewOnlyHolder;
    }, []);

    return {
      type: 'atf',
      inViewOnly,
      posToRefresh,
      refreshBidders,
      userActionDelay,
    };

  }
);

export const btfUserActionRefreshAdConfigSelector = createDeepEqualSelector(
  userActionRefreshAdConfigSelector,
  pageKeySelector,
  localeSelector,
  (configs, pageKey, locale) => {
    const {
      btf,
      refreshBidders,
    } = configs;
    const localesInConfigs = Object.keys(btf) || [];
    const locales2Refresh = localesInConfigs.filter(key => key.includes(locale) || key.includes('all')) || [];
    let positionsHolder = configs?.btf?.positions;
    let posToRefresh = locales2Refresh.reduce((acc, cur) => {
      // Collect all positions under locale, pageKey and 'all'
      const pageKeys = Object.keys(btf[cur]);
      const thePageKey = (pageKeys.filter(key => key.includes(pageKey) || key.includes('all')) || [])[0];

      if (thePageKey) {
        positionsHolder = btf?.[cur]?.[thePageKey]?.positions ?? positionsHolder;

        return positionsHolder;
      }

      return acc;
    }, []);

    if (isEmpty(posToRefresh)) {
      posToRefresh = positionsHolder;
    }
    let userActionDelayHolder = configs?.btf?.userActionDelay;
    const userActionDelay = locales2Refresh.reduce((acc, cur) => {
      // Collect all intervals under locale, pageKey and 'all'
      const pageKeys = Object.keys(btf[cur]);
      const thePageKey = (pageKeys.filter(key => key.includes(pageKey) || key.includes('all')) || [])[0];

      if (thePageKey) {
        userActionDelayHolder = btf?.[cur]?.[thePageKey]?.userActionDelay || userActionDelayHolder;
      }
      return userActionDelayHolder;
    }, []);
    let inViewOnlyHolder = configs?.btf?.inViewOnly;
    const inViewOnly = locales2Refresh.reduce((acc, cur) => {
      // Collect all intervals under locale, pageKey and 'all'
      const pageKeys = Object.keys(btf[cur]);
      const thePageKey = (pageKeys.filter(key => key.includes(pageKey) || key.includes('all')) || [])[0];

      if (thePageKey) {
        inViewOnlyHolder = btf?.[cur]?.[thePageKey]?.inViewOnly ?? inViewOnlyHolder;
      }
      return inViewOnlyHolder;
    }, []);

    return {
      type: 'btf',
      inViewOnly,
      posToRefresh,
      refreshBidders,
      userActionDelay,
    };

  }
);

export const timerRefreshAdConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find(config => {
        if (config.type) {
          return config.type === THIRD_PARTIES.REFRESH_ADS.token;
        }
        return false;
      });

      if (matchedConfig && matchedConfig.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const atfRefreshAdConfigSelector = createDeepEqualSelector(
  timerRefreshAdConfigSelector,
  pageKeySelector,
  localeSelector,
  (refreshConfigs, pageKey, locale) => {
    const {
      atf = {},
      refreshBidders = [],
    } = refreshConfigs;
    const { interval = 0, inViewOnly = false } = atf;
    const localesInConfigs = Object.keys(atf);
    const locales2Refresh = localesInConfigs.filter(key => key.includes(locale) || key.includes('all')) || [];
    let positionsHolder = atf.positions;
    const positions = locales2Refresh.reduce((acc, cur) => {
      // Collect all positions under locale, pageKey and 'all'
      const pageKeys = Object.keys(atf[cur]);
      const thePageKey = pageKeys.filter(key => key.includes(pageKey) || key.includes('all')) || [];

      if (!isEmpty(thePageKey)) {
        positionsHolder = atf[cur][thePageKey].positions;
      }
      return positionsHolder;
    }, []);

    return {
      type: 'atf',
      inViewOnly,
      interval,
      positions,
      refreshBidders,
    };
  }
);

export const sequentialAdLoaderConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const sequentialAdLoaderConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES?.SEQUENTIAL_AD_LOADER?.token;
        }
        return false;
      });

      if (sequentialAdLoaderConfig?.config) {
        return sequentialAdLoaderConfig.config;
      }
      return {};
    }
  }
);

export const useSequentialAdLoaderSelector = createDeepEqualSelector(
  sequentialAdLoaderConfigSelector,
  deviceClassSelector,
  pageKeySelector,
  localeSelector,
  (sequentialAdLoaderConfig, deviceClass, pageKey, locale) => {
    if (isEmpty(sequentialAdLoaderConfig) || deviceClass !== 'desktop') return false;

    const localesInConfig = Object.keys(sequentialAdLoaderConfig);
    const localeKeyArray = localesInConfig.filter(key => key.includes(locale) || key.includes('all')) || [];
    const localeKey = Array.isArray(localeKeyArray) && localeKeyArray[0];
    const pageKeys = sequentialAdLoaderConfig?.[localeKey];
    const pageKeyArray = pageKeys && (pageKeys.filter(key => key.includes(pageKey) || key.includes('all')) || []);
    const enabled = pageKeyArray && Array.isArray(pageKeyArray) && !!pageKeyArray[0];

    return enabled;
  }
);



export const rubiconConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES?.RUBICON?.token;
        }
        return false;
      });

      if (matchedConfig?.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const bidderTimeoutSelector = createDeepEqualSelector(
  rubiconConfigSelector,
  pageKeySelector,
  localeSelector,
  (rubiconConfig, pageKey, locale) => {
    /**
    Configuration for timeoutOverride looks like the following:
      <comma separated locales or 'all'> : {
         <comma separated pageKeys or 'all'> : {
            timeoutOverride: 500
         }
      }
    */
    const { defaultTimeoutOverride } = rubiconConfig;
    const localesInConfigs = Object.keys(rubiconConfig);
    const localeKeys = localesInConfigs.filter(key => key.includes(locale) || key.includes('all')) || [];
    let timeoutHolder = defaultTimeoutOverride;
    const timeout = localeKeys.reduce((acc, curr) => {
      const pageKeys = Object.keys(rubiconConfig[curr]);
      const thePageKey = pageKeys.filter(key => key.includes(pageKey) || key.includes('all')) || [];

      if (!isEmpty(thePageKey)) {
        timeoutHolder = rubiconConfig[curr][thePageKey[0]].timeoutOverride;
      }
      return timeoutHolder;
    }, timeoutHolder);

    return timeout;
  }
);

export const rubiconPageConfigSelector = createDeepEqualSelector(
  rubiconConfigSelector,
  bidderTimeoutSelector,
  (rubiconConfig, timeout) => {
    const configs = {
      filename: rubiconConfig?.filename,
      testFilename: rubiconConfig?.testFilename,
      timeout,
    };

    return configs;
  }

);

export const btfRefreshAdConfigSelector = createDeepEqualSelector(
  timerRefreshAdConfigSelector,
  pageKeySelector,
  localeSelector,
  (refreshConfigs, pageKey, locale) => {
    const {
      btf = {},
      refreshBidders = [],
    } = refreshConfigs;
    const { interval = 0, inViewOnly = false } = btf;
    const localesInConfigs = Object.keys(btf);
    const locales2Refresh = localesInConfigs.filter(key => key.includes(locale) || key.includes('all')) || [];
    let positionsHolder = btf.positions;
    const positions = locales2Refresh.reduce((acc, cur) => {
      // Collect all positions under locale, pageKey and 'all'
      const pageKeys = Object.keys(btf[cur]);
      const thePageKey = pageKeys.filter(key => key.includes(pageKey) || key.includes('all')) || [];

      if (!isEmpty(thePageKey)) {
        positionsHolder = btf[cur][thePageKey].positions;
      }
      return positionsHolder;
    }, []);

    return {
      type: 'btf',
      interval,
      inViewOnly,
      positions,
      refreshBidders,
    };
  }
);

export const wfxtgConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES.WFXTG.token;
        }
        return false;
      });

      if (matchedConfig && matchedConfig.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const wxAdTargetingConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES.WX_AD_TARGETING.token;
        }
        return false;
      });

      if (matchedConfig && matchedConfig.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const iasPetSlotsSelector = createDeepEqualSelector(
  nctauSelector,
  createAdConfigsSelector(null),
  (nctau, adModules) => adModules.map(adModule => ({
    adSlotId: adModule.slotTarget,
    size: adModule.sizes.map(size => (size[0] === 'fluid' ? [1, 1] : size)),
    adUnitPath: nctau,
  })),
);

export const firstViewSelector = () => {
  if (__CLIENT__) {
    const fv = Cookies.get('fv');

    return parseInt(fv, 10) || 0;
  }
};

export const catFmChFromAdZoneSelector = createDeepEqualSelector(
  collectionAdZoneSelector,
  pageAdZoneSelector,
  (collectionAdZone, pageAdZone) => {
    const adZone = collectionAdZone || pageAdZone || '';
    const isLocalForecastAdZone = adZone.lastIndexOf('local_forecasts') !== -1;

    let cat;

    let fam;

    let ch;

    if (isLocalForecastAdZone) {
      cat = 'fcst';
      ch = 'fcst';
      fam = 'fcst';
    } else {
      const adZoneParts = adZone.split('/');
      const famCh = adZoneParts.slice(-2);

      [fam] = famCh;
      cat = `${fam}_${(famCh[1] || '')}`;
      ch = famCh[1] || 'nl';
    }

    return {
      cat,
      fam,
      ch,
    };
  }
);

export const timeFrameConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const timeframeConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES?.TIME_FRAME?.token;
        }
        return false;
      });

      if (timeframeConfig?.config) {
        return timeframeConfig.config;
      }
    }
    return {};
  }
);

export const timeFrameSelector = createDeepEqualSelector(
  pageCodeSelector,
  timeFrameConfigSelector,
  (pageCode, timeframeConfig) => (timeframeConfig?.tf?.[pageCode] ?? 'nl'));

export const randomStringSelector = (inlen, inchars) => () => {
  const chars = inchars || '0123456789';
  const len = inlen || 16;

  let ret = '';

  for (let i = 0; i < len; i++) {
    ret += chars.charAt(Math.floor(Math.random() * chars.length) % chars.length);
  }
  return ret;
};

export const browserSelector = createSelector(() => {
  if (__CLIENT__) {
    const { userAgent } = window.navigator;
    const browser = userAgent && userAgent.match(/chrome|firefox|safari|trident/i);

    if (Array.isArray(browser)) {
      switch (browser[0].toLowerCase()) {
        case 'chrome':
          return 'twcchrome';
        case 'firefox':
          return 'twcff';
        case 'safari':
          return 'twcsafari';
        case 'trident':
          return 'twcie';
        default:
          return 'twcnative';
      }
    }

    return 'nl';
  }
});

export const envSelector = () => String(Math.floor(Math.random() * 1000) + 1);
export const refurlSelector = () => 'weather';
export const wfxtgTargetingSelector = state => state?.[REDUCER_KEY]?.wfxtg ?? {};
export const lotameTargetingSelector = state => state?.[REDUCER_KEY]?.lotame ?? {};
export const hashedMpidSelector = state => state?.[REDUCER_KEY]?.hashedMpid ?? '';

export const platformSelector = createDeepEqualSelector(
  screenSizeSelector,
  (screenSize) => {
    if (screenSize === 'tablet') {
      return 'wx_tab';
    }
    if (screenSize === 'mobile') {
      return 'wx_mw';
    }

    return 'wx';
  }
);

export const mlayerSelector = createDeepEqualSelector(
  serverPageStateQuerySelector,
  (queryParams = {}) => {
    if (queryParams.layer === 'rwi' || queryParams.layers === 'rwi') {
      return 'rwi';
    }
  }
);

export const videoCountSelector = state => state?.[VIDEO_REDUCER_KEY]?.videoCount ?? 0;

export const queryParamAdRefSelector = createDeepEqualSelector(
  serverPageStateQuerySelector,
  /**
   * @param  {Object<string,string>} queryParams
   * @return {string}
   */
  queryParams => queryParams?.adRef ?? '',
);

export const allCustParamsSelector = state => state?.[REDUCER_KEY]?.custParams ?? {};

export const miscCustParamsSelector = createDeepEqualSelector(
  allowCustParamsPiiSelector,
  allowGptPiiSaleUSASelector,
  catFmChFromAdZoneSelector,
  pageAdUnitsSelector,
  timeFrameSelector,
  randomStringSelector(16, '0123456789'),
  firstViewSelector,
  adsTestSelector,
  browserSelector,
  platformSelector,
  envSelector,
  refurlSelector,
  mlayerSelector,
  queryParamAdRefSelector,
  videoCountSelector,
  partnerSelector,
  screenSizeSelector,
  deviceClassSelector,
  (
    allowCustParamsPii,
    allowGptPiiSaleUSA,
    catFamCh,
    adUnits,
    tf,
    randomString,
    fv,
    adsTest,
    browser,
    plat,
    env,
    refurl,
    mlayer,
    adRef,
    videoCount,
    par,
    screenSize,
    deviceClass,
  ) => {
    let miscCustParams = {
      ...catFamCh,
      ad_unit: adUnits[screenSize],
      tf,
      ord: randomString,
      rmid: randomString,
      impression_id: uuid(),
      vab: deviceClass !== 'mobile' ? 'nxgn' : 'daybreak',
      v: fv && fv.toString(),
      adstest: !adsTest ? '' : adsTest,
      browser,
      mr: '0',
      plat,
      env,
      refurl,
      mlayer: !mlayer ? '' : mlayer,
      ...(par ? { par } : {}),
      ref: videoCount === 0 ? adRef : '',
      sod: allowGptPiiSaleUSA ? 'yes' : 'no', // Sale Of Data
    };

    miscCustParams = pickBy(miscCustParams, prop => !!prop);

    // WEB-21715 remove AUDIENCE_CAPTIVATION_PROPS_2_REMOVE from cust_params targeting
    const finalCustParams = omit(miscCustParams, AUDIENCE_CAPTIVATION_PROPS_2_REMOVE);

    if (allowCustParamsPii) {
      return finalCustParams;
    }

    return omit(finalCustParams, PII_CUST_PARAM_KEYS);
  },
);

export const biddingProcessTimeoutConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const matchedConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES.BIDDING_PROCESS_TIMEOUT.token;
        }
        return false;
      });

      if (matchedConfig && matchedConfig.config) {
        return matchedConfig.config;
      }
    }
    return {};
  }
);

export const biddingProcessTimeoutSelector = createDeepEqualSelector(
  biddingProcessTimeoutConfigSelector,
  (biddingProcessTimeoutConfigs) => {
    if (biddingProcessTimeoutConfigs && !isEmpty(biddingProcessTimeoutConfigs)) {
      const { maxTime } = biddingProcessTimeoutConfigs;

      if (!Number.isNaN(maxTime)) {
        return +maxTime;
      }
    }
    return -1;
  }
);

/**
 * Returns Helios config stored in thirdPartyConfig in moonracer with this object:
 * {
 *    "src": "https://s-dev.w-x.co/helios/twc/helios.js",
 *    "enable": true,
 *    "en-US,es-US": [   // an array of pages to enable Helios. If "all" then all pages will use Helios.
 *     "home",
 *     "tenday",
 *     "today"
 *    ]
 *  }
 */
export const heliosAdSdkConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const heliosConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES?.HELIOS_AD_SDK?.token;
        }
        return false;
      });

      if (heliosConfig?.config) {
        return heliosConfig.config;
      }
    }
    return {};
  }
);

export const instanaPageKvsMetaDataSelector = createDeepEqualSelector(
  allCustParamsSelector,
  hashedMpidSelector,
  (custParams, ppid) => {
    const hasPageKvs = Object.keys(custParams).filter(k => INSTANA_PAGE_KVS.has(k)).sort();
    const pageKvs = hasPageKvs.length ? ` ${hasPageKvs.join(' ')} ` : '';

    return {
      pageKvs,
      hasPpid: !!ppid,
    };
  },
);

export const abTestingConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => {
    if (!isEmpty(thirdPartyConfigs)) {
      const abTestingConfig = thirdPartyConfigs.find((config) => {
        if (config.type) {
          return config.type === THIRD_PARTIES?.AB_TESTING?.token;
        }
        return false;
      });

      if (abTestingConfig?.config) {
        return abTestingConfig.config;
      }
    }
    return {};
  }
);

export const iabCategoriesThirdPartyConfigSelector = createDeepEqualSelector(
  adThirdPartyConfigsSelector,
  (thirdPartyConfigs) => thirdPartyConfigs?.find?.(
    (config) => config.type === THIRD_PARTIES.IAB_CATEGORIES.token
  )?.config ?? {},
);

function mergeIabCategories(c1, c2) {
  return mergeWith({}, c1, c2, (a, b) => {
    if (Array.isArray(a)) {
      return uniq(a.concat(b));
    }
  });
}

export const iabCategoriesByPageCodeSelector = createDeepEqualSelector(
  iabCategoriesThirdPartyConfigSelector,
  (iabCategoriesConfig) => {
    const { iabCategoriesByPageCode = {} } = iabCategoriesConfig ?? {};
    const categories = {};

    for (const [keys, value] of Object.entries(iabCategoriesByPageCode)) {
      for (const key of keys.split(',')) {
        categories[key] = mergeIabCategories(categories[key] ?? {}, value);
      }
    }

    return categories;
  },
);

export const iabCategoriesForCurrentPageCodeSelector = createDeepEqualSelector(
  iabCategoriesByPageCodeSelector,
  pageCodeSelector,
  (iabCategoriesByPageCode, pageCode) => iabCategoriesByPageCode[pageCode]
);

export const iabCategoriesForActiveAssetSelector = createDeepEqualSelector(
  articleSelector,
  activeVideoSelector,
  (article, activeVideo) => {
    const activeAsset = !isEmpty(article) ? article : activeVideo;

    return activeAsset?.tags?.iab ?? {};
  },
);

export const iabPageCategoriesSelector = createDeepEqualSelector(
  iabCategoriesForCurrentPageCodeSelector,
  iabCategoriesForActiveAssetSelector,
  (iabCategoriesForCurrentPageCode, iabCategoriesForActiveAsset) => {
    const merged = mergeIabCategories(iabCategoriesForCurrentPageCode, iabCategoriesForActiveAsset);

    return merged;
  }
);

export const iabSiteCategoriesSelector = createDeepEqualSelector(
  iabCategoriesThirdPartyConfigSelector,
  (iabCategoriesConfig) => iabCategoriesConfig?.iabSiteCategories,
);
