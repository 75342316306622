import url from 'url';

/**
 * Gets appropriately translated URL for both location-based and non-location-based pages.
 * @param  {Object}   options
 * @param  {string}   options.path                 E.g. `/${locale}/weather/today/l/${locId}`
 * @param  {string}   options.locale
 * @param  {string}   [options.placeId]
 * @param  {boolean}  [options.includeEnUsLocale] Bypass filtering of en-US locale from locale token
 * @param  {Object}   [options.urlConfig]         'url'-parseable  config object
 * @return {string}
 */
export function interpolateUrl({
  path,
  locale,
  placeId,
  includeEnUsLocale = false,
  urlConfig = null,
  customLanguage,
  query = {},
}) {
  let pathname = path;

  if (!pathname || !locale) return '';

  const language = customLanguage || locale.split('-')[0];

  if (pathname.includes('${locale}')) {
    if (!includeEnUsLocale && locale === 'en-US') {
      pathname = pathname.replace(/\/\$\{locale\}\/?/, '/');
    } else {
      pathname = pathname.replace('${locale}', locale);
    }
  }

  pathname = pathname
    // Fill in `locId`, if present
    .replace('${locId}', placeId || '')
    // Add `lang`, if present
    .replace('${lang}', language)
    // Set `locale_`, if present
    .replace('${locale_}', locale.replace('-', '_'));

  // merge query params from urlConfig
  const { query: urlConfigQuery } = (urlConfig || {});
  const mergedQuery = {
    ...urlConfigQuery, // query param consisting of locId/placeId
    ...query, // other query params
  };
  const urlObj = {
    ...urlConfig,
    pathname,
    query: mergedQuery,
  };
  const formattedUrl = url.format(urlObj);

  return decodeURIComponent(formattedUrl);
}
