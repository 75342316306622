export const REDUCER_KEY = 'videoWithPlaylist';
export const INITIAL_STATE = {
  activeVideoId: '',
  activePlaylistId: '',
  previousPlaylistId: '',
  videoCount: 0,
};
export const JW_PLAYER_ID = 'VideoPagePlayer';
export const BASE_NATIVE_ADZONE = 'video/contentfeed';
export const DEFAULT_NATIVE_AD_POSITION = 'MW_Position_ContentFeedAd';

// Action types
export const LOAD_NEXT_VIDEO = 'WXU/VIDEO/LOAD_NEXT_VIDEO';
export const SAVE_NEXT_VIDEO_DATA = 'WXU/VIDEO/SAVE_NEXT_VIDEO_DATA';
export const SAVE_NATIVE_AD_POSITIONS = 'WXU/VIDEO/SAVE_NATIVE_AD_POSITIONS';
export const NATIVE_AD_ERROR = 'WXU/VIDEO/NATIVE_AD_ERROR';
export const INCREMENT_VIDEO_COUNT = 'WXU/VIDEO/INCREMENT_VIDEO_COUNT';
export const SET_AD_REF = 'WXU/VIDEO/SET_AD_REF';
export const CONTENT_FEED_NATIVE_AD_CALL = 'WXU/VIDEO/CONTENT_FEED_NATIVE_AD_CALL';
export const SET_TEST_VARIANT = 'WXU/VIDEO/SET_TEST_VARIANT';
export const SAVE_ACTIVE_VIDEO_POSITION = 'WXU/VIDEO/SAVE_ACTIVE_VIDEO_POSITION';
