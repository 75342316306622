import { CLIENT_SIDE_START } from './constants';
import { measureAndLog } from './measureAndLog';

/**
 * Creates a peformance measurement against the client-side start mark.
 * @param name
 * @param attributes
 * @returns
 */
export function measureSinceStart(name, attributes = {}) {
  return measureAndLog(name, CLIENT_SIDE_START, attributes);
}
